import React from 'react';
import classNames from 'classnames';
import './NXHelpBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    isOpen?: boolean;
    position?: 'left' | 'right';
    children?: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The HelpBox component provides a standardized box that should be used to provide help text to the user.
 * This is in the format of a title, description and a list of bullet points, similar to the NXBox but with bullet points.
 * The NXHelpBox is a compound component of the HelpBox
 */

export const _NXHelpBox: React.FC<Props> = ({ isOpen, position, children }) => {
    /***** RENDER *****/
    return (
        <div className={classNames('NXHelpBox', { 'NXHelpBox--open': isOpen, [`NXHelpBox--position-${position}`]: position })}>
            <div className="NXHelpBox__triangle">
                <div className="NXHelpBox__triangleInner"></div>
            </div>
            {children}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
