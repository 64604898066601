import type { InfiniteData, QueryKey } from '@tanstack/react-query';
import queryClient from 'store/queryClient';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

export function createSetInfiniteQueriesDataMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(
    createQueryKey: (params: TParams) => QueryKey
) {
    return function setQueriesData(params: TParams, updater: (oldData?: InfiniteData<TData>) => InfiniteData<TData> | undefined) {
        queryClient.setQueriesData({ queryKey: createQueryKey(params) }, updater);
    };
}
