/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import designAndColoursModule from 'assets/images/katana/design-and-colours-module-area.png';
import finishingTouchesModule from 'assets/images/katana/finishing-touches-module-area.png';
import navigationModule from 'assets/images/katana/navigation-module-area.png';
import webpagePresetContentModule from 'assets/images/katana/webpage-preset-content-module-area.png';
import websiteInformationModule from 'assets/images/katana/website-information-module-area.png';
import { createPictureInPictureState } from 'components/PictureInPicture/consts';
import type { KatanaSetupEditorRouteParams } from 'containers/katana/types';
import type { AnyRouteId } from 'router/types';

export const katanaModuleImages = {
    websiteInformationModule,
    webpagePresetContentModule,
    designAndColoursModule,
    navigationModule,
    finishingTouchesModule
} as const;

export const katanaSubPageEnums = {
    BASIC_DETAILS: 'basic-details',
    PRESET_CONTENT: 'template-content',
    NAVIGATION: 'navigation-and-socials',
    COLOUR_FONTS: 'colours-fonts',
    CONTACT_INFORMATION: 'contact-information',
    FINALISE: 'finalise',
    REGISTER_DOMAIN: 'register-domain',
    LAUNCH: 'launch',
    FIX_DNS: 'fix-dns',
    ADD_SECTION: 'add-section'
} as const;

const { BASIC_DETAILS, PRESET_CONTENT, NAVIGATION, COLOUR_FONTS, FINALISE, LAUNCH, CONTACT_INFORMATION, REGISTER_DOMAIN, FIX_DNS, ADD_SECTION } =
    katanaSubPageEnums;

export const SiteSetupStage = {
    WebpageInfoCompleted: 'webpage-info-completed',
    LayoutAndContentCompleted: 'layout-and-content-completed',
    // NavigationCompleted: 'navigation-completed',
    ThemeAndColorCompleted: 'theme-and-color-completed',
    FinishingTouchesCompleted: 'finishing-touches-completed'
} as const;

export const SiteSetupStageOrder = [
    SiteSetupStage.WebpageInfoCompleted,
    SiteSetupStage.ThemeAndColorCompleted,
    SiteSetupStage.LayoutAndContentCompleted,
    // SiteSetupStage.NavigationCompleted,
    SiteSetupStage.FinishingTouchesCompleted
] as const;

export type SetupStages = (typeof SiteSetupStageOrder)[number];

export const SiteSetupStageToTitle = {
    [SiteSetupStage.WebpageInfoCompleted]: 'BASIC_DETAILS',
    [SiteSetupStage.LayoutAndContentCompleted]: 'PRESET_CONTENT',
    // [SiteSetupStage.NavigationCompleted]: 'NAVIGATION',
    [SiteSetupStage.ThemeAndColorCompleted]: 'COLOUR_FONTS',
    [SiteSetupStage.FinishingTouchesCompleted]: 'FINALISE'
} as const;

export const SiteSetupStageToSubPage = {
    [SiteSetupStage.WebpageInfoCompleted]: BASIC_DETAILS,
    [SiteSetupStage.LayoutAndContentCompleted]: PRESET_CONTENT,
    // [SiteSetupStage.NavigationCompleted]: NAVIGATION,
    [SiteSetupStage.ThemeAndColorCompleted]: COLOUR_FONTS,
    [SiteSetupStage.FinishingTouchesCompleted]: FINALISE
} as const;

export const SiteSetupStageSubPageOrder = SiteSetupStageOrder.map((stage) => SiteSetupStageToSubPage[stage]);

export const katanaSubPageToSetupStage = {
    [BASIC_DETAILS]: SiteSetupStage.WebpageInfoCompleted,
    [PRESET_CONTENT]: SiteSetupStage.LayoutAndContentCompleted,
    // [NAVIGATION]: SiteSetupStage.NavigationCompleted,
    [COLOUR_FONTS]: SiteSetupStage.ThemeAndColorCompleted,
    [FINALISE]: SiteSetupStage.FinishingTouchesCompleted
} as const;

export const LAUNCH_CHECKLIST_ENUM_VALUES = Object.values(SiteSetupStage);

export const katanaPages = {
    ADD_SECTION: 'add-section',
    REGISTER: 'register'
} as const;

export const katanaServicePages = {
    OVERVIEW: {
        to: 'overview',
        BASIC_DETAILS: `overview#${BASIC_DETAILS}`,
        PRESET_CONTENT: `overview#${PRESET_CONTENT}`,
        NAVIGATION: `overview#${NAVIGATION}`,
        COLOUR_FONTS: `overview#/${COLOUR_FONTS}`,
        FINALISE: `overview#${FINALISE}`
    },
    ADMIN: {
        to: 'admin',
        RESET: 'admin#reset',
        CANCEL: 'admin#cancel',
        DNS: 'admin#dns'
    },
    SETUP: {
        to: 'setup',
        BASIC_DETAILS: `setup/${BASIC_DETAILS}`,
        CONTACT_INFORMATION: `setup/${CONTACT_INFORMATION}`,
        PRESET_CONTENT: `setup/${PRESET_CONTENT}`,
        NAVIGATION: `setup/${NAVIGATION}`,
        COLOUR_FONTS: `setup/${COLOUR_FONTS}`,
        FINALISE: `setup/${FINALISE}`,
        REGISTER_DOMAIN: `setup/${REGISTER_DOMAIN}`,
        LAUNCH: `setup/${LAUNCH}`,
        ADD_SECTION: `setup/${ADD_SECTION}`
    },
    EDITOR: {
        to: 'editor',
        BASIC_DETAILS: `editor/${BASIC_DETAILS}`,
        CONTACT_INFORMATION: `editor/${CONTACT_INFORMATION}`,
        PRESET_CONTENT: `editor/${PRESET_CONTENT}`,
        NAVIGATION: `editor/${NAVIGATION}`,
        COLOUR_FONTS: `editor/${COLOUR_FONTS}`,
        FINALISE: `editor/${FINALISE}`,
        REGISTER_DOMAIN: `editor/${REGISTER_DOMAIN}`,
        LAUNCH: `editor/${LAUNCH}`,
        FIX_DNS: `editor/${FIX_DNS}`,
        ADD_SECTION: `editor/${ADD_SECTION}`
    }
} as const;

export const NOT_ROUTED = () => alert('Not Routed');

const KatanaSectionIDS = {
    KATANA_V1_ABOUT: 'katana.v1.about',
    KATANA_V1_BIOGRAPHY: 'katana.v1.biography',
    KATANA_V1_CALL_TO_ACTION: 'katana.v1.call-to-action',
    KATANA_V1_CONTACT: 'katana.v1.contact',
    KATANA_V1_FAQ: 'katana.v1.faq',
    KATANA_V1_GALLERY: 'katana.v1.gallery',
    KATANA_V1_HERO: 'katana.v1.hero',
    KATANA_V1_INSTAGRAM_FEED: 'katana.v1.instagram-feed',
    KATANA_V1_SERVICES: 'katana.v1.services',
    KATANA_V1_TEAM: 'katana.v1.team',
    KATANA_V1_TESTIMONIALS: 'katana.v1.testimonials'
} as const;

export const KatanaColours = {
    ORANGE: 'katana--orange',
    GREEN: 'katana--green',
    AQUA: 'katana--aqua',
    PURPLE: 'katana--purple',
    YELLOW: 'katana--yellow',
    PINK: 'katana--pink',
    GRAY: 'katana--gray',
    RED: 'katana--red'
} as const;

export const KatanaSectionIDHardCodedMetaData = {
    [KatanaSectionIDS.KATANA_V1_HERO]: {
        color: KatanaColours.GREEN
    },
    [KatanaSectionIDS.KATANA_V1_ABOUT]: {
        color: KatanaColours.AQUA
    },
    [KatanaSectionIDS.KATANA_V1_SERVICES]: {
        color: KatanaColours.PURPLE
    },
    [KatanaSectionIDS.KATANA_V1_GALLERY]: {
        color: KatanaColours.YELLOW
    },
    [KatanaSectionIDS.KATANA_V1_BIOGRAPHY]: {
        color: KatanaColours.GRAY
    },
    [KatanaSectionIDS.KATANA_V1_CALL_TO_ACTION]: {
        color: KatanaColours.PINK
    },
    [KatanaSectionIDS.KATANA_V1_TEAM]: {
        color: KatanaColours.RED
    },
    [KatanaSectionIDS.KATANA_V1_TESTIMONIALS]: {
        color: KatanaColours.ORANGE
    },
    [KatanaSectionIDS.KATANA_V1_FAQ]: {
        color: KatanaColours.GREEN
    },
    [KatanaSectionIDS.KATANA_V1_CONTACT]: {
        color: KatanaColours.AQUA
    },
    [KatanaSectionIDS.KATANA_V1_INSTAGRAM_FEED]: {
        color: KatanaColours.PURPLE
    }
} as const;

export const katanaOverviewPictureInPictureState = createPictureInPictureState('hidden', 'katanaOverviewPictureInPictureState');

/**
 * Note, routes where the exact subpage is not part of the route tree will need to be specified.
 *
 * ie. setup/template-content/$childPage will not match the route setup/template-content, and therefore needs it's own map
 */
export const KatanaSubpageRouteMap: Partial<Record<AnyRouteId, KatanaSetupEditorRouteParams['subpage']>> = {
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/basic-details': 'basic-details', //
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/contact-information': 'contact-information',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content': 'template-content',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content/$childPage': 'template-content',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/navigation-and-socials': 'navigation-and-socials',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/colours-fonts': 'colours-fonts',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/finalise': 'finalise',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/register-domain': 'register-domain',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/launch': 'launch',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/launch': 'launch',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/finalise': 'finalise',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/fix-dns': 'fix-dns',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/basic-details': 'basic-details',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/contact-information': 'contact-information',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/navigation-and-socials': 'navigation-and-socials',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/colours-fonts': 'colours-fonts',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/template-content': 'template-content',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/template-content/$childPage': 'template-content'
};

export const KatanaPageRouteMap: Partial<Record<AnyRouteId | (string & {}), KatanaSetupEditorRouteParams['page'] | 'admin'>> = {
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup': 'setup',
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor': 'editor',
    '/my-services/vip-sites/$katanaServiceId/overview': 'overview',
    '/my-services/vip-sites/$katanaServiceId/admin': 'admin'
};
