import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import { formatUnderlineActionClass } from 'components/Form/RichTextEditor/plugins/Toolbar/consts';
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { MouseEventHandler } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormatUnderlineAction = () => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const iconSize = useRichTextIconSize();

    /***** FUNCTIONS *****/
    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        editor?.chain().focus().toggleUnderline().run();
    };

    /***** RENDER HELPERS *****/
    const isActive = editor?.isActive('underline');

    /***** RENDER *****/
    return (
        <ToolbarButton className={formatUnderlineActionClass} ariaLabel="Format Underline" onClick={onClick} isActive={isActive}>
            <PhosphorIcons.TextUnderline.Bold size={iconSize} black={isActive} />
        </ToolbarButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
