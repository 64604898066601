import _ from 'lodash';
import type { NXUtils } from 'utilities/NXUtils';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

// prettier-ignore
/**
 * This is being used as part of `createOptimisticFilterMethod`
 *
 * This method serves the consumer to help restore an optimistic filtering of data at a specific location
 */
export function createOptimisticFilterRestoreMethod<
    TParams extends any,
    TData extends NXQueryUtils.ApiData,
    TKey extends NXUtils.Path<TData>,
    TFilterValue extends NXUtils.Choose<TData, TKey> extends Array<any> 
		? NXUtils.Choose<TData, TKey>[number] 
		: never
>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>,
    params: TParams,
    filterPath: TKey,
    previousEntriesDictionary: Record<number, TFilterValue>
) {
    if (!_.keys(previousEntriesDictionary).length) {
        return _.noop;
    }

    function restoreOptimisticFilter() {
        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            if (!_.has(oldData, filterPath)) {
                return;
            }

            const clonedData = _.cloneDeep(oldData);

            const value = _.get(clonedData, filterPath);

            if (!_.isArray(value)) {
                return;
            }

            _.entries(previousEntriesDictionary).forEach(([index, filteredValue]) => {
                value.splice(Number(index), 0, filteredValue);
            });

            return clonedData;
        });
    }

    return restoreOptimisticFilter;
}
