import type { UseResizeObserverCallback } from '@react-hook/resize-observer';
import useResizeObserver from '@react-hook/resize-observer';
import type { RefObject } from 'react';
import { useRef, useState } from 'react';
import { useIsMounted } from 'usehooks-ts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

/** The size of the observed element. */
type Size = {
    /** The width of the observed element. */
    width: number | undefined;
    /** The height of the observed element. */
    height: number | undefined;
};

type BoxModel = 'border-box' | 'content-box' | 'device-pixel-content-box';

/** The options for the ResizeObserver. */
type UseResizeObserverOptions<T extends HTMLElement = HTMLElement> = {
    /** The ref of the element to observe. */
    ref: RefObject<T>;
    /**
     * When using `onResize`, the hook doesn't re-render on element size changes; it delegates handling to the provided callback.
     * @default undefined
     */
    onResize?: (size: Size) => void;
    /**
     * The box model to use for the ResizeObserver.
     * @default 'content-box'
     */
    box?: BoxModel;
};

/** @private */
type BoxSizesKey = keyof Pick<ResizeObserverEntry, 'borderBoxSize' | 'contentBoxSize' | 'devicePixelContentBoxSize'>;

const initialSize: Size = {
    width: undefined,
    height: undefined
};

function getBoxProp(box: BoxModel) {
    switch (box) {
        case 'border-box':
            return 'borderBoxSize';
        case 'device-pixel-content-box':
            return 'devicePixelContentBoxSize';
        default:
            return 'contentBoxSize';
    }
}

/**
 * Custom hook that observes the size of an element using the [`ResizeObserver API`](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver).
 * @template T - The type of the element to observe.
 * @param {UseResizeObserverOptions<T>} options - The options for the ResizeObserver.
 * @returns {Size} - The size of the observed element.
 * @example
 * ```tsx
 * const myRef = useRef(null);
 * const { width = 0, height = 0 } = useResizeObserver({
 *   ref: myRef,
 *   box: 'content-box',
 * });
 *
 * <div ref={myRef}>Hello, world!</div>
 * ```
 */
export function useElementSize<T extends HTMLElement = HTMLElement>(options: UseResizeObserverOptions<T>): Size {
    const { ref, box = 'content-box' } = options;
    const [{ width, height }, setSize] = useState<Size>(initialSize);
    const isMounted = useIsMounted();
    const previousSize = useRef<Size>({ ...initialSize });
    const onResize = useRef<((size: Size) => void) | undefined>(undefined);
    onResize.current = options.onResize;

    const handleObserver: UseResizeObserverCallback = (entry) => {
        const boxProp = getBoxProp(box);

        const newWidth = extractSize(entry, boxProp, 'inlineSize');
        const newHeight = extractSize(entry, boxProp, 'blockSize');

        const hasChanged = previousSize.current.width !== newWidth || previousSize.current.height !== newHeight;

        if (hasChanged) {
            const newSize: Size = { width: newWidth, height: newHeight };
            previousSize.current.width = newWidth;
            previousSize.current.height = newHeight;

            if (onResize.current) {
                onResize.current(newSize);
            } else {
                if (isMounted()) {
                    setSize(newSize);
                }
            }
        }
    };

    useResizeObserver(ref, handleObserver);

    return { width, height };
}

function extractSize(entry: ResizeObserverEntry, box: BoxSizesKey, sizeType: keyof ResizeObserverSize): number | undefined {
    if (!entry[box]) {
        if (box === 'contentBoxSize') {
            return entry.contentRect[sizeType === 'inlineSize' ? 'width' : 'height'];
        }
        return undefined;
    }

    if (Array.isArray(entry[box])) {
        return entry[box][0][sizeType];
    }

    // @ts-ignore Support Firefox's non-standard behavior
    return entry[box][sizeType] as number;
}
