import type { QueryKey } from '@tanstack/react-query';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { checkDomain } from 'containers/katana/queries/checkDomain';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { MINUTE } from 'utilities/consts';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.GET>>;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID(serviceID) as QueryKey;
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: ({ signal }) =>
            KATANA_API.katana.service_id.GET(serviceID, signal).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function cancelQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(serviceID) });
}

function getQueryData(serviceID: KatanaNamespace.ServiceID): undefined | TData {
    return queryClient.getQueryData(createQueryKey(serviceID));
}

function ensureQueryData(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.ensureQueryData(createQueryOptions(serviceID));
}

function invalidateQueries(serviceID: KatanaNamespace.ServiceID) {
    const queryKey = createQueryKey(serviceID);
    // Whenever the service is invalidated, we also need to invalidate the preview check as it uses the service data
    const queryData = getQueryData(serviceID);
    // Whenever the service is invalidated, we also need to invalidate the domain check as it uses the service data
    if (queryData?.status !== 200 && queryData?.data?.attributes?.name) {
        checkDomain.invalidateQueries(queryData.data.attributes.name);
    }
    return queryClient.invalidateQueries({
        queryKey
    });
}

function resetQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.resetQueries({ queryKey: createQueryKey(serviceID) });
}

/**
 * As a potential concept to follow a more "parent invalidates all children" approach to query key invalidation we can do something along the lines of the following code
 *
 * Where `invalidateExact` invalidates just that query.
 *
 * Where `invalidateLoose` invalidates that query + all the child queries.
 *
 * i.e.:
 *
 * if our queryKey is `[userId, 'serviceId']`
 *
 * and our queries are:
 * ```
 * [userId]
 * [userId, 'serviceId']
 * [userId, 'serviceId', 123]
 * ```
 *
 * if we were to call `invalidateExact`. only `[userId, 'serviceId']` would get invalidated
 *
 * if we were to call `invalidateLoose`. the following queries would be invalidated:
 * ```
 * [userId, 'serviceId']
 * [userId, 'serviceId', 123]
 * ```
 */
// const createBaseQueryKeyWithInvalidation = (...args) => {
//     const queryKey = createBaseQueryKey(args);
//     const invalidateExact = queryClient.invalidateQueries(queryKey)
//     const invalidateLoose = queryClient.invalidateQueries({
//       predicate: (key: Array<string | number>) =>
//         queryKey.every((_key, i) => key[i] === _key)
//   })
// }

//   const createQueryKey = (serviceId) => {
//     const { queryKey, invalidateExact, invalidateLoose } = createBaseQueryKeyWithInvalidation("example", serviceId)
//   }

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery<TQueryOptions extends NXQueryUtils.TUseQueryOptions<typeof createQueryOptions>>(
    serviceID?: KatanaNamespace.ServiceID,
    options?: TQueryOptions
) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    return useQuery({
        ...createQueryOptions(serviceID ?? katanaServiceId),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const service = Object.freeze({
    useQuery: _useQuery,
    optimistic: createOptimisticMethods(setQueryData),
    cancelQueries,
    resetQueries,
    invalidateQueries,
    prefetchQuery,
    getQueryData,
    createQueryKey,
    createQueryOptions,
    ensureQueryData
});
