/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useMemo, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { performValidations } from 'utilities/methods/commonActions/performValidation/performValidation';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_InputDecoupledDefault.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { ChangeEvent } from 'react';
import type { NXInputNamespace } from 'components/Form/Input/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This input component handles all the logic for validation and other input validations.
 * It's decoupled so that it works independently of any form framework
 */
export const _InputDecoupledDefault: React.FC<NXInputNamespace.Decoupled.Props> = (props) => {
    const {
        label,
        name,
        value = null,
        children,
        placeholder,
        validate = [],
        inputRef,
        validationBorderOnly,
        intrinsicProps,
        onEmitValidationResult,
        fullWidth,
        className
    } = props;

    /***** STATE *****/
    const [touched, setTouched] = useState(false);

    /***** FUNCTIONS *****/
    function _onChange(v: ChangeEvent<HTMLInputElement>) {
        setTouched(true);
        intrinsicProps?.onChange?.(v);
    }

    /***** HOOKS *****/
    const validationResult = useMemo(() => {
        // if one of validate is invalid, the input is invalid and return the value from the first invalidation
        return validate.length ? performValidations(validate, value) : undefined;
    }, [validate, value]);

    /***** EFFECTS *****/
    useEffect(() => {
        onEmitValidationResult?.(validationResult);
    }, [validationResult]);

    /***** RENDER *****/
    return (
        <FormItem name={name} className={className}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner
                borderOnly={validationBorderOnly}
                meta={{
                    error: validationResult,
                    touched,
                    warning: undefined,
                    pristine: false,
                    initial: undefined
                }}
                fullWidth={fullWidth}
            >
                <Input
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    inputRef={inputRef}
                    intrinsicProps={{ ...intrinsicProps, onChange: _onChange }}
                />

                {children}
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
