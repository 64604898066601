import type { KatanaAPI } from 'utilities/api/katana/types';

export function applyNewSectionSort(sectionIDs: number[], sections: KatanaAPI.Katana.Site.ServiceID.Sections.GET.Sections_200_DataEntryMerged[]) {
    sectionIDs.forEach((sectionID, index) => {
        const section = sections.find((section) => section.id === sectionID);
        if (section) {
            section.order = index;
        }
    });

    return sections;
}
