/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { filterInstagramType } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/filterInstagramType';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import _ from 'lodash';
import { createPopup } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConnectAccountProps = {
    setIsAuthorising: (isAuthorising: boolean) => void;
    propertyKey: string;
    isAuthorising: boolean;
    hasAttemptedAuthorisation: boolean;
    isRequestTimedOut: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ConnectAccount: React.FC<ConnectAccountProps> = ({
    propertyKey,
    setIsAuthorising,
    isAuthorising,
    hasAttemptedAuthorisation,
    isRequestTimedOut
}) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const currentInstagramAccountsList = useRef<any[]>();
    const { dynamicChange } = useDynamicFormActions();

    /***** QUERIES *****/
    const {
        mutateAsync: mutateSocialAuthorisationLink,
        isPending: isSocialAuthorisationLinkPending,
        isError: isSocialAuthorisationLinkError
    } = katanaQuery.serviceID.socialsAuthorisationLink.useMutation(katanaServiceId);
    const { data: instagramAccounts, isSuccess: isListSocialAccountsSuccess } = katanaQuery.serviceID.listSocialAccounts.useQuerySelector(
        katanaServiceId,
        filterInstagramType
    );

    /***** FUNCTIONS *****/
    function handleConnectAccount() {
        const { goToTargetUrl, closePopup } = createPopup();

        mutateSocialAuthorisationLink('instagram_business')
            .then((authorisationLinkData) => {
                if (authorisationLinkData.status === 200) {
                    setIsAuthorising(true);
                    goToTargetUrl(authorisationLinkData.data.link);
                }
            })
            .catch((e) => {
                setIsAuthorising(false);
                closePopup();
            });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (instagramAccounts && isListSocialAccountsSuccess && !isAuthorising && hasAttemptedAuthorisation) {
            const accountDifference = _.difference(instagramAccounts, currentInstagramAccountsList.current ?? []);
            const differedAccount = accountDifference.pop();
            if (differedAccount) {
                dynamicChange(propertyKey, differedAccount.id);
            }
            currentInstagramAccountsList.current = instagramAccounts;
        }
    }, [instagramAccounts, isAuthorising, isListSocialAccountsSuccess, hasAttemptedAuthorisation]);

    /***** RENDER HELPERS *****/
    const renderErrorMessage = () => {
        if (isSocialAuthorisationLinkError) {
            return <Text warn>Something went wrong. Please try again later.</Text>;
        }

        if (isRequestTimedOut) {
            return <Text warn>Request timed out. Please try again</Text>;
        }
    };

    /***** RENDER *****/
    if (isSocialAuthorisationLinkPending) {
        return <RequestLoader message="Getting Authorisation Link..." />;
    }

    return (
        <>
            {renderErrorMessage()}
            <SolidButton type="onClick" width="full" onClick={handleConnectAccount}>
                Connect {instagramAccounts && instagramAccounts?.length >= 1 ? 'Another' : ''} Account
            </SolidButton>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
