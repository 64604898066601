import classNames from 'classnames';
import { _RichTextEditor as RichTextEditor } from 'components/Form/RichTextEditor/_RichTextEditor';
import type { RichTextEditorInputNamespace } from 'components/Form/RichTextEditor/_RichTextEditorInput/types';
import React, { useEffect } from 'react';
import './_RichTextEditorInput.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RichTextEditorInput: React.FC<RichTextEditorInputNamespace.Props> = ({ value, onChange, onBlur, features, initialValue }) => {
    /***** HOOKS *****/
    useEffect(() => {
        if (!features?.includes('no_new_line')) {
            console.error('no_new_line is disabled for RichTextEditorInput');
        }
    }, [features]);

    /***** RENDER *****/
    return <RichTextEditor key={initialValue} value={value} onChange={onChange} className={classNames('RichTextEditorInput')} features={features} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
