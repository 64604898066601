import classNames from 'classnames';
import type { ToolbarButtonNamespace } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/types';
import { ToolBarToolTip } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolBarToolTip/ToolBarToolTip';
import Grid from 'components/Grid';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';
import React from 'react';
import './_ToolbarButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ToolbarButton: React.FC<ToolbarButtonNamespace.Props> = ({
    children,
    className,
    disabled,
    onClick,
    ariaLabel,
    isActive,
    width,
    isToolTipDisabled,
    text
}) => (
    <WrapWithComponent wrap={!isToolTipDisabled} component={ToolBarToolTip} ariaLabel={ariaLabel}>
        <Flex justify="center" items="center" inject>
            <button
                disabled={disabled}
                onClick={onClick}
                className={classNames(
                    'ToolbarButton',
                    {
                        'ToolbarButton--isActive': isActive,
                        'ToolbarButton--disabled': disabled,
                        'ToolbarButton--autoWidth': width === 'auto',
                        'ToolbarButton--fullWidth': width === 'full'
                    },
                    className
                )}
                type="button"
                aria-label={ariaLabel}
            >
                <WrapWithComponent component={Grid} columns="auto 1fr" fullWidth wrap={!!text}>
                    {children}

                    {text && (
                        <Text size--s semiBold>
                            {text}
                        </Text>
                    )}
                </WrapWithComponent>
            </button>
        </Flex>
    </WrapWithComponent>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
