import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import { actionMap } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/consts';
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';
import type { ToolbarButtonNamespace } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/types';
import type { MouseEventHandler } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    intrinsicProps?: Partial<ToolbarButtonNamespace.Props>;
    onAfterClick?: MouseEventHandler<HTMLButtonElement>;
};

const ActionIcon = actionMap.ol.icon;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const OrderedListAction: React.FC<Props> = ({ onAfterClick, intrinsicProps }) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const iconSize = useRichTextIconSize();

    /***** FUNCTIONS *****/
    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        editor?.chain().focus().toggleOrderedList().run();
        onAfterClick?.(e);
    };

    /***** RENDER HELPERS *****/
    const isActive = editor?.isActive('orderedList');

    /***** RENDER *****/
    return (
        <ToolbarButton ariaLabel="Number List" onClick={onClick} isActive={isActive} {...intrinsicProps}>
            <ActionIcon size={iconSize} black={isActive} />
        </ToolbarButton>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
