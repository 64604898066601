import type { UsePositionEditorElementParams } from 'components/Form/RichTextEditor/hooks/usePositionEditorElement/types';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { getSelectedElementDomRect } from 'components/Form/RichTextEditor/methods/getSelectedElementDomRect';
import _ from 'lodash';
import { useCallback } from 'react';
import type { UseTextSelectionNamespace } from 'utilities/hooks/useTextSelection/types';
import { useTextSelectionChangedEffect } from 'utilities/hooks/useTextSelection/useTextSelectionChangedEffect';

const windowPadding = 5;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const usePositionEditorElement = ({
    rootElement,
    elementToCalculateCenterToRef,
    align = 'center',
    anchor = 'bottom',
    anchorOffset = 10
}: UsePositionEditorElementParams) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();

    /***** FUNCTIONS *****/
    const positionEditorElement = useCallback(
        (selectionData: UseTextSelectionNamespace.Data) => {
            const { selection } = selectionData;
            if (
                selection &&
                selection.type !== 'None' &&
                elementToCalculateCenterToRef?.current &&
                rootElement !== null &&
                rootElement?.contains(selection.anchorNode)
            ) {
                const elementCurrent = elementToCalculateCenterToRef.current;
                const domRangeRect = getSelectedElementDomRect(align);
                if (!domRangeRect) {
                    return;
                }

                const top = `${domRangeRect.top + domRangeRect.height}px`;
                const left = `${domRangeRect.left}px`;

                elementCurrent.style.top = top;
                elementCurrent.style.left = left;

                const editorElementRect = elementCurrent.getBoundingClientRect();

                const rangeWidthHalf = domRangeRect.width / 2;

                const inputElementHalf = editorElementRect.width / 2;
                const differenceX = domRangeRect.left - editorElementRect.left + rangeWidthHalf - inputElementHalf;
                const differenceY = domRangeRect.top - editorElementRect.top;

                if (anchor === 'bottom') {
                    elementCurrent.style.top = `${_.round(parseFloat(elementCurrent.style.top) + differenceY + anchorOffset)}px`;
                } else if (anchor === 'top') {
                    elementCurrent.style.top = `${_.round(parseFloat(elementCurrent.style.top) + differenceY - editorElementRect.height - anchorOffset)}px`;
                }

                if (align === 'center') {
                    elementCurrent.style.left = `${_.round(parseFloat(elementCurrent.style.left) + differenceX)}px`;
                } else if (align === 'left') {
                    elementCurrent.style.left = `${_.round(parseFloat(elementCurrent.style.left) + differenceX + inputElementHalf - rangeWidthHalf)}px`;
                }

                // Check Left and right bounding edge
                const editorElementBoundingRect = elementCurrent?.getBoundingClientRect?.();
                if (editorElementBoundingRect.left < windowPadding) {
                    const missingSpace =
                        editorElementBoundingRect.left < 0
                            ? Math.abs(editorElementBoundingRect.left) + windowPadding
                            : windowPadding - editorElementBoundingRect.left;

                    elementCurrent.style.left = `${_.round(parseFloat(elementCurrent.style.left) + missingSpace)}px`;
                }
                if (editorElementBoundingRect.right > window.innerWidth - windowPadding) {
                    const missingSpace = editorElementBoundingRect.right - (window.innerWidth - windowPadding);
                    elementCurrent.style.left = `${_.round(parseFloat(elementCurrent.style.left) - missingSpace)}px`;
                }
            }
        },
        [editor, elementToCalculateCenterToRef?.current, rootElement]
    );

    /***** EFFECTS *****/
    useTextSelectionChangedEffect(rootElement, positionEditorElement);
};

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
