import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import _ from 'lodash';
import { useCollectionIncludes } from 'utilities/methods/collectionIncludes/useCollectionIncludes';

export const useRichTextFeatures = (feature: RichTextEditorNamespace.Features | RichTextEditorNamespace.Features[]) => {
    /***** HOOKS *****/
    const [features] = useRichTextEditorContext('features');
    /***** HOOK RESULTS *****/
    return useCollectionIncludes(features, _.flatten([feature]));
};
