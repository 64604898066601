import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { MINUTE } from 'utilities/consts';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createUseQuerySelectorMethod } from 'utilities/methods/tanstack/createUseQuerySelectorMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.user.socials.GET>>;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.user.socials(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.user.socials.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function invalidateQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);
const getQueryData = createGetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(serviceID: KatanaNamespace.ServiceID, options?: NXQueryUtils.TUseQueryOptions<typeof createQueryOptions>) {
    // This causes a type issue if you don't pass options
    return useQuery({ ...createQueryOptions(serviceID), ...options });
}

const useQuerySelector = createUseQuerySelectorMethod<KatanaNamespace.ServiceID, TData, typeof createQueryOptions>(createQueryOptions);
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const listSocialAccounts = Object.freeze({
    useQuery: _useQuery,
    useQuerySelector,
    setQueryData,
    getQueryData,
    optimistic: createOptimisticMethods(setQueryData),
    invalidateQueries
});
