import { RichTextEditor } from 'components/Form/RichTextEditor';
import { getServiceInfoDataFromPath } from 'containers/katana/components/dynamicFormFieldRenderer/methods';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetRichTextCaveat } from 'containers/katana/formFields/methods/getRichTextCaveat/useGetRichTextCaveat';
import { getValidationValue } from 'containers/katana/formFields/methods/getValidation';
import { useDisabledCaveat } from 'containers/katana/formFields/methods/useDisabledCaveat';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import type { KatanaNamespace } from 'containers/katana/types';
import { get } from 'lodash';
import { useContext, useMemo } from 'react';
import { Field } from 'redux-form';
import { useReduxForm } from 'utilities/hooks/useReduxForm';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaTextFormFieldRichTextComponent = React.FC<{
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
}>;

const customElementMatcher = [
    (key?: string) => {
        const matched = key?.match(/properties\.work_project\[(\d)\]\.images\[(\d)\]\.caption/);
        const hasMatched = matched?.length === 3;
        return hasMatched ? RichTextEditor.Input.ReduxForm : RichTextEditor.TextArea.ReduxForm;
    }
];

function useMatcher<TMatcherList extends ((key: string) => any)[]>(matcherList: TMatcherList, key: string) {
    return useMemo(() => {
        for (const matcher of matcherList) {
            const matchResult = matcher(key);
            if (matchResult) return matchResult;
        }
    }, [matcherList.length, key]);
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaTextFormFieldRichText: KatanaTextFormFieldRichTextComponent = ({ property }) => {
    const { defaultValue, key, name, type, caveats, validationFunctions, validation } = property;
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { form } = useContext(ContentEditorRenderModulesContext);
    const values = useReduxForm(form);
    const initialValue = get(values?.initial, key);

    /***** RENDER HELPERS *****/
    const disabled = useDisabledCaveat(caveats);
    const valueFromServiceDataPath = useGetCaveat(CaveatTypes.VALUE_FROM_SERVICE_DATA, caveats);
    const richTextFeatures = useGetRichTextCaveat(caveats);
    const valueFromServiceData = getServiceInfoDataFromPath(katanaServiceId, valueFromServiceDataPath);
    const placeHolderCaveat = useGetCaveat(CaveatTypes.PLACEHOLDER, caveats) ?? defaultValue;
    const placeholder = valueFromServiceData ?? placeHolderCaveat;

    const maxLength = getValidationValue(validation, 'max');
    const minLength = getValidationValue(validation, 'min');

    const richTextElement = useMatcher(customElementMatcher, key);

    const isRichText = richTextFeatures?.length;
    if (!isRichText) {
        console.error('Rich text features are not defined for the rich text field');
        return null;
    }

    /***** RENDER *****/
    return (
        <Field
            key={`${key}_richTextFeaturesLength_${richTextFeatures?.length}`}
            name={key}
            label={name}
            placeholder={placeholder}
            component={richTextElement}
            rows={5}
            disabled={disabled}
            type={type}
            validate={validationFunctions}
            formItemInnerProps={{
                noBackground: true,
                fullWidth: true
            }}
            features={richTextFeatures}
            initialValue={initialValue}
            intrinsicProps={{
                maxLength,
                minLength
            }}
        />
    );
};
