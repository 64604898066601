import type {
    RichTextTipTapJSON,
    TipTapDocumentJSON
} from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';

export function formatTipTapData(value: TipTapDocumentJSON): RichTextTipTapJSON {
    return {
        type: 'tiptap',
        content: value
    };
}
