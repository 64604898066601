import { API_BASE_URL } from 'App/consts';
import type { AxiosInstance } from 'axios';
import axios from 'axios';

/**
 * This function can be used to set axios defaults on either the global axios, or an instance of axios.
 */
export function setAxiosDefaults(instance: AxiosInstance | null = null) {
    const axiosInstance = instance || axios;
    axiosInstance.defaults.baseURL = API_BASE_URL;

    if (window?.ccpIsAppWebview) {
        const accessToken = localStorage.getItem('access_token');
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
        axiosInstance.defaults.withCredentials = true;
    }
}
