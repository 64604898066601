import auLogosStacked from 'assets/images/promo/jackpot20k/au-logos-stacked.svg';
import auLogos from 'assets/images/promo/jackpot20k/au-logos.svg';
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import { NXTooltip } from 'components/NXTooltip';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_jackpot20kBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Jackpot20kBanner = () => {
    /***** HOOKS *****/
    const isTabletSize = useAppViewport(['sm', 'md']);
    const isMobileSize = useAppViewport(['xs']);

    /***** FUNCTIONS *****/
    const getColumns = () => {
        if (isMobileSize) return '1fr';
        if (isTabletSize) return 'minmax(210px, 1fr) minmax(100px, 200px)';
        return '1fr minmax(100px, 200px)';
    };

    /***** RENDER *****/
    return (
        <div className="jackpot20k__container">
            <Flex direction="column" items="end" gap={0} fullHeight>
                <div className="jackpot20k__containerBox">
                    <Grid columns={getColumns()} gap={4} alignItems--center justifyItems--center>
                        <Flex className="jackpot20k__containerBoxText" fullHeight fullWidth justify="center" direction="column" items="center">
                            <Text size--xxxl blackFont white align--center>
                                Transfer for <Text blackFont>FREE</Text> and
                                <br />
                                <Text blackFont>WIN*</Text> a Share of <Text blackFont>$20,000!</Text>
                            </Text>
                            <img className="jackpot20k__containerLogos" src={isMobileSize ? auLogosStacked : auLogos} alt=".au logos" />
                        </Flex>

                        <SolidButton
                            type="anchor"
                            target="_blank"
                            href="https://ventraip.com.au/transfer-jackpot/"
                            className="jackpot20k__containerBoxButton"
                        >
                            Find out more
                        </SolidButton>
                    </Grid>
                </div>
                <Text className="jackpot20k__containerText" size--xs color="S_color_text_grey_base">
                    <NXTooltip
                        title={
                            <Text>
                                Any AU domain names transferring with 90 days or less remaining until expiration will require a renewal at the time of
                                transfer. Renewal prices vary depending on the specific domain name extension.
                            </Text>
                        }
                    >
                        <Text underline>*T&apos;s&C&apos;s Apply. </Text>
                    </NXTooltip>
                    NSW Authority No. TP/03444. ACT Permit No. TP 24/01862.1. SA Licence No. T24/1425.{' '}
                </Text>
            </Flex>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Jackpot20kBanner.JACKPOT_DISMISS_KEY = 'JACKPOT_DISMISS_KEY';
