import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useDisabledCaveat(caveats?: KatanaNamespace.SectionDefinitions.AllCaveats[]) {
    return Boolean(useGetCaveat(CaveatTypes.DISABLED, caveats));
}
