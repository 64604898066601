import NXBox from 'components/NXBox';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PropertyNXBoxTitle: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { caveats } = property;
    /***** HOOKS *****/
    const title = useGetCaveat(CaveatTypes.BOX_TITLE, caveats) as string | null | undefined;

    /***** RENDER HELPERS *****/
    if (!title) return null;
    return <NXBox.Title title={title} />;
};
