import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { MouseEventHandler } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormatHighlightAction = () => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const iconSize = useRichTextIconSize();

    /***** FUNCTIONS *****/
    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        editor?.chain().focus().toggleHighlight().run();
    };

    /***** RENDER HELPERS *****/
    const isActive = editor?.isActive('highlight');

    /***** RENDER *****/
    return (
        <ToolbarButton ariaLabel="Highlight" onClick={onClick} isActive={isActive}>
            <PhosphorIcons.Highlighter.Bold size={iconSize} black={isActive} />
        </ToolbarButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
