import type { Editor } from '@tiptap/core';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { createJSONContent } from 'components/Form/RichTextEditor/methods/createJSONContent';
import type { TipTapDocumentJSON } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import type React from 'react';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    onChange: (value: TipTapDocumentJSON) => void;
    onBlur?: (value: TipTapDocumentJSON) => void;
};

type HandleChangeValue = { editor: Editor };

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const JSONExtractorPlugin: React.FC<Props> = ({ onChange, onBlur }) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();

    /***** FUNCTIONS *****/
    const handleOnChange = ({ editor }: HandleChangeValue) => {
        onChange(createJSONContent(editor.getJSON()));
    };

    const handleOnBlur = ({ editor }: HandleChangeValue) => {
        onBlur?.(createJSONContent(editor.getJSON()));
    };

    /***** EFFECTS *****/
    useEffect(() => {
        if (!editor) return;
        editor.on('create', handleOnChange);
        editor.on('update', handleOnChange);

        if (onBlur) {
            editor.on('blur', handleOnBlur);
        }

        return () => {
            editor.off('create', handleOnChange);
            editor.off('update', handleOnChange);

            if (onBlur) {
                editor.off('blur', handleOnBlur);
            }
        };
    }, [editor, onChange]);

    /***** RENDER *****/
    return null;
};
