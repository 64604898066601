import { regexes } from 'utilities/methods/regexes';

export default function getYoutubeThumbnailLink(youTubeVideoLink: string) {
    const regExp = regexes.youtubeLinkWithID;
    const match = youTubeVideoLink.match(regExp());
    const youTubeVideoId = match && match[1] && match[1].length === 11 ? match[1] : false;
    if (youTubeVideoId) {
        return `https://img.youtube.com/vi/${youTubeVideoId}/0.jpg`;
    }
    return youTubeVideoLink;
}
