import { _NXHelpBox } from 'components/NXHelpBox/_NXHelpBox';
import { _NXHelpBoxBullets } from 'components/NXHelpBox/Bullets/_NXHelpBoxBullets';
import { _NXHelpBoxBulletItem } from 'components/NXHelpBox/Bullets/_NXHelpBoxBulletItem';
import { _NXHelpBoxDescription } from 'components/NXHelpBox/Description/_NXHelpBoxDescription';
import { _NXHelpBoxLink } from 'components/NXHelpBox/Link/_NXHelpBoxLink';
import { _NXHelpBoxTitle } from 'components/NXHelpBox/Title/_NXHelpBoxTitle';

export const NXHelpBox = Object.assign(_NXHelpBox, {
    Title: _NXHelpBoxTitle,
    Description: _NXHelpBoxDescription,
    Bullets: _NXHelpBoxBullets,
    BulletItem: _NXHelpBoxBulletItem,
    Link: _NXHelpBoxLink
});
