// Function to convert image URL to Data URI

import { API_BASE_URL } from 'App/consts';
import { nxAxios } from 'store/nxAxios';

function handleFileLoad(response, resolve) {
    const reader = new FileReader();
    reader.onloadend = function () {
        resolve(reader.result);
    };
    reader.readAsDataURL(response);
}

/**
 * @param {string} url
 * @returns {Promise<string>}
 */
export function urlToDataUri(url) {
    return new Promise((resolve, reject) => {
        if (url.startsWith(API_BASE_URL)) {
            return nxAxios
                .get(url.replace(API_BASE_URL, ''), { responseType: 'blob' })
                .then((response) => {
                    return handleFileLoad(response, resolve);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    reject(error);
                });
        }
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            handleFileLoad(xhr.response, resolve);
        };
        xhr.onerror = function () {
            console.log('xhr.onerror');
            reject(new Error('Failed to fetch image'));
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
}
