/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { collectionIncludes } from 'utilities/methods/collectionIncludes/collectionIncludes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useCollectionIncludes<TSearchCollection extends _.List<unknown>>(
    collectionToSearch?: TSearchCollection,
    collectionToFind?: Array<NXUtils.ValueOf<TSearchCollection>> | string[]
) {
    // Not an arrow function because formatting is gookie
    /***** HOOK RESULTS *****/
    return useMemo(
        () => (collectionToSearch && collectionToFind ? collectionIncludes(collectionToSearch, collectionToFind) : false),
        [collectionToSearch, collectionToFind]
    );
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
