import { useAccordionTitleValueTargetCaveat } from 'containers/katana/formFields/caveats/useAccordionTitleValueTargetCaveat';
import { useSingularNameCaveat } from 'containers/katana/formFields/caveats/useSingularNameCaveat';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { useMemo } from 'react';
import type { FieldArrayFieldsProps } from 'redux-form';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useAccordionTitle = (
    property: KatanaNamespace.SectionDefinitions.PropertiesModified,
    fields: FieldArrayFieldsProps<unknown>,
    index: number
) => {
    /***** HOOKS *****/
    const singularName = useSingularNameCaveat(property);
    const fieldValues = fields.get(index) as Record<string, unknown>;
    const accordionTitleFormValueTarget = useAccordionTitleValueTargetCaveat(property);
    return useMemo(() => {
        if (accordionTitleFormValueTarget && _.has(fieldValues, accordionTitleFormValueTarget)) {
            return fieldValues[accordionTitleFormValueTarget];
        }
        return singularName;
    }, [fieldValues, accordionTitleFormValueTarget, singularName]);
};
