import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import type { PartialDeep } from 'type-fest';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    /**
     * The section ID to update
     */
    sectionID: KatanaNamespace.SectionID;

    /**
     * The katana section ID to add
     */
    katanaSectionID: KatanaNamespace.SectionDefinitionID;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleSectionAddOrUpdate({ sectionID, katanaSectionID }: Params) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const mutationParams = { serviceID: katanaServiceId, katanaSectionID };

    /**********************************************************************************************************
     *   QUERIES
     **********************************************************************************************************/
    const { mutateAsync: mutateAdd } = katanaQuery.serviceID.addSection.useMutation(mutationParams);
    const { mutateAsync: mutateUpdate } = katanaQuery.serviceID.updateSection.useMutation(mutationParams);

    /***** FUNCTIONS *****/
    function submitSectionData(attributes: PartialDeep<KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.ModifiedSection_200['data']>) {
        /**
         * Because the mutations are async,
         * we need to catch any errors that occur so that tanstack can still follow it's regular flow.
         * whilest keeping the button's loading state active.
         */
        if (sectionID) {
            /**
             * Update the section as it already exists
             */
            return mutateUpdate({ sectionID, attributes });
        }

        /**
         * Add the section as it doesn't exist yet
         */
        return mutateAdd({ sectionDefinitionID: katanaSectionID, attributes });
    }

    /***** RENDER *****/
    return { submitSectionData };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
