/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Ref } from 'react';

/**
 * Checks if a pointer event is within a list of refs
 */
export function isPointerEventWithinRefList(e: PointerEvent | MouseEvent, refs: Array<Ref<Element> | Element | null>) {
    // Composed Path allows checking within shadow DOMs
    const composedPath = e.composedPath?.() || [];
    return refs.some((ref) => {
        const current = ref instanceof Element ? ref : ref?.current;
        // Perform a runtime check to ensure e.target is actually a Node
        if (!current || !(e.target instanceof Node)) {
            return false;
        }
        const refContainsTarget = current?.contains?.(e.target);
        const composedPathContainsRef = composedPath.includes(current);
        return composedPathContainsRef || refContainsTarget;
    });
}
