import { createOptimisticInfiniteQuerySetMethod } from 'utilities/methods/tanstack/optimistic/infiniteQuery/createOptimisticInfiniteQuerySetMethod';
import { createOptimisticInfiniteQuerySetWithMethod } from 'utilities/methods/tanstack/optimistic/infiniteQuery/createOptimisticInfiniteQuerySetWithMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**
 * Creates optimistic update methods for Setting, Merging and Filtering infinite query data at specific layers of the infinite query data object
 *
 * Each function factory returns a function
 * - when the function is called the data in the tanstack query store is updated.
 * - the called function then returns an object with a method `restore` on it, allowing the consumer to "undo" their optimistic update.
 */
export function createOptimisticInfiniteQueryMethods<TParams extends any, TData extends NXQueryUtils.ApiData>(
    setQueryData: NXQueryUtils.SetInfiniteQueryDataMethod<TParams, TData>
) {
    const set = createOptimisticInfiniteQuerySetMethod(setQueryData);
    const setWith = createOptimisticInfiniteQuerySetWithMethod(setQueryData);
    // const filter = createOptimisticFilterMethod(setQueryData);
    // const merge = createOptimisticMergeMethod(setQueryData);
    // const concat = createOptimisticConcatMethod(setQueryData);

    /***** RESULTS *****/
    return Object.assign(set, {
        // filter,
        set,
        setWith
        // merge,
        // concat
    });
}
