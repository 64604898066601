import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { sections } from 'containers/katana/queries/serviceID/sections';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { SECOND } from 'utilities/consts';
import { createDataObjectCreatorMethod } from 'utilities/methods/tanstack/createDataObjectCreatorMethod';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import { createOptimisticResponseObject } from 'utilities/methods/tanstack/optimistic/createOptimisticResponseObject';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TParams = {
    serviceID: KatanaNamespace.ServiceID;
    sectionID: KatanaNamespace.SectionID | null;
};
type TData = Awaited<ReturnType<typeof KATANA_API.katana.site.service_id.section.section_id.GET>>;
type TData200 = NXQueryUtils.ApiData200<TData>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(params: TParams) {
    return katanaQueryKeys.katana.service.ID.sections.ID(params);
}

function createQueryOptions({ serviceID, sectionID }: TParams) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, sectionID }),
        queryFn: () => KATANA_API.katana.site.service_id.section.section_id.GET({ serviceID, sectionID }),
        staleTime: SECOND * 25,
        enabled: Boolean(serviceID && sectionID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
        initialData: () => {
            const queryResult = sections.getQueryData(serviceID);
            if (queryResult?.status === 200) {
                const foundData = queryResult.data.find((section) => section.id === sectionID);
                if (foundData) {
                    return createOptimisticResponseObject<TData200['data']>(foundData);
                }
            }
            return undefined;
        },
        initialDataUpdatedAt: () => sections.getQueryState(serviceID)?.dataUpdatedAt
    });
}

const setQueryData = createSetQueryDataMethod<TParams, TData200>(createQueryKey);
const getQueryData = createGetQueryDataMethod<TParams, TData200>(createQueryKey);

const removeQueries = (params: TParams) => {
    queryClient.removeQueries({ queryKey: createQueryKey(params), exact: true });
};

const createDataObject = createDataObjectCreatorMethod<TData200>();

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery(params: TParams) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const section = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    getQueryData,
    createDataObject,
    removeQueries,
    optimistic: createOptimisticMethods(setQueryData)
});
