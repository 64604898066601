import { DragHandle } from 'components/DragHandle';
import Grid from 'components/Grid';
import { SortableItem } from 'components/SortableItem';
import { WrapWithComponent } from 'components/WrapWithComponent';
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { useSingularNameCaveat } from 'containers/katana/formFields/caveats/useSingularNameCaveat';
import { useActionHandler } from 'containers/katana/formFields/repeated/OptionsIcon/useActionHandler';
import { KatanaRepeatedFormFieldRemoveButton } from 'containers/katana/formFields/repeated/removeButton';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/useModifiedFieldArrayProperties';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { useMemo } from 'react';
import './__InlineListFieldArrayChild.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

const customGridColumnsMatchers = [
    (key?: string) => {
        const matched = key?.match(/properties\.work_project\[(\d)\]\.images/);
        const hasMatched = matched ? matched.length === 2 : false;
        return hasMatched ? '6rem minmax(0, 1fr)' : false;
    }
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _InlineListFieldArrayChild: React.FC<KatanaNamespace.RepeatedPropertyFieldArrayChildProps<unknown>> = ({
    member,
    index,
    fields,
    properties,
    property,
    sortable
}) => {
    /***** HOOKS *****/
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const handleAction = useActionHandler({ index, fields, property, member });

    /***** RENDER HELPERS *****/
    // have to modify the properties to include the member key
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);
    const singularName = useSingularNameCaveat(property);

    const fieldValues = fields.get(index);

    const matchedCustomGridColumns = useMemo(() => {
        for (const matcher of customGridColumnsMatchers) {
            const matchResult = matcher(property.key);
            if (matchResult) return matchResult;
        }
    }, [property.key]);

    /***** FUNCTIONS *****/
    const gridColumns = useMemo(() => {
        const currentColumns = matchedCustomGridColumns || `repeat(${modifiedProperties.length}, auto)`;
        return sortable ? `${DragHandle.width}px ${currentColumns}` : currentColumns;
    }, [modifiedProperties]);

    /***** RENDER *****/
    return (
        <WrapWithComponent component={SortableItem} id={fieldValues?.[SORTABLE_ID_KEY]} wrap={sortable}>
            <div className="KatanaRepeatedFormField__inlineDisplayItem">
                <KatanaRepeatedFormFieldRemoveButton removeService={() => handleAction('delete')} singularName={singularName} />
                <ContentEditorModuleContent>
                    <Grid columns={gridColumns} alignItems--start>
                        {sortable && <DragHandle.DNDKit fullHeight id={fieldValues?.[SORTABLE_ID_KEY]} />}
                        <PropertiesRenderer properties={modifiedProperties} />
                    </Grid>
                </ContentEditorModuleContent>
            </div>
        </WrapWithComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
