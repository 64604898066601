import OutlineButton from 'components/Buttons/OutlineButton';
import type { EntryCreationBehaviourHandlerCoreProps } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper/types';
import { useSingularNameCaveat } from 'containers/katana/formFields/caveats/useSingularNameCaveat';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenderDefaultButton: React.FC<EntryCreationBehaviourHandlerCoreProps> = ({ property, fields, properties }) => {
    /***** FUNCTIONS *****/
    function addNew() {
        fields.push({});
    }

    /***** RENDER HELPERS *****/
    const singularName = useSingularNameCaveat(property);

    /***** RENDER *****/
    return (
        <OutlineButton className="KatanaRepeatedFormField__addAnotherButton" type="onClick" onClick={addNew}>
            Add another {singularName ?? property.name}
        </OutlineButton>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
