import { useIsFetching } from '@tanstack/react-query';
import OutlineButton from 'components/Buttons/OutlineButton';
import RequestLoader from 'components/Loaders/Request';
import { useKatanaServiceSiteListSetupURL } from 'containers/katana/hooks/useKatanaServiceSetupURL';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type Params = {
    katanaSiteData: KatanaAPI.Katana.GET.Modified200['data'][number];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function KatanaServiceListEntryActionButton({ katanaSiteData }: Params) {
    const { attributes, id: serviceID } = katanaSiteData;
    const status = attributes?.site_status;
    const serviceStatus = attributes?.status;
    const isPendingCancellation = serviceStatus === 'pending cancellation';

    /***** HOOKS *****/
    const finalKatanaURL = useKatanaServiceSiteListSetupURL(serviceID);
    const isListFetching = Boolean(
        useIsFetching({
            queryKey: katanaQueryKeys.katana.service.list()
        })
    );

    /***** QUERIES *****/
    const { mutate: mutateRemoveCancellation, isPending: isRemoveCancellationPending } =
        katanaQuery.serviceID.removeCancellation.useMutation(serviceID);

    /***** FUNCTIONS *****/
    function handlePrefetch() {
        if (!serviceID) {
            return;
        }
        katanaQuery.serviceID.service.prefetchQuery(serviceID);
    }

    function handleRemoveCancellation() {
        mutateRemoveCancellation();
    }

    /***** RENDER *****/
    if (isPendingCancellation) {
        return (
            <OutlineButton color="warn" disabled={isListFetching || isRemoveCancellationPending} onClick={handleRemoveCancellation} size="small">
                {isListFetching ? <RequestLoader /> : 'Keep service'}
            </OutlineButton>
        );
    }
    return (
        <OutlineButton disabled={status === 'suspended'} to={finalKatanaURL} onMouseOver={handlePrefetch} size="small">
            {attributes?.is_setup_complete ? 'Manage' : 'Setup'}
        </OutlineButton>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default KatanaServiceListEntryActionButton;
