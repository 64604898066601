import { type RichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import { plaintTextToMarkdown } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/plainTextToMarkdown';
import { getRichTextCaveat } from 'containers/katana/formFields/methods/getRichTextCaveat/getRichTextCaveat';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';

export function processRichTextProperty<TValue>(
    value: TValue,
    propertyDefinition: KatanaNamespace.SectionDefinitions.PropertiesModified
): RichTextData | TValue {
    const richTextFeatures = getRichTextCaveat(propertyDefinition.caveats);

    if (typeof value === 'string' && _.includes(richTextFeatures, 'markdown')) {
        return {
            type: 'markdown',
            content: plaintTextToMarkdown(value)
        };
    }

    return value;
}
