import { PhosphorIcons } from 'components/Icons/Phosphor';

export const actionMap = {
    paragraph: {
        name: 'Paragraph',
        icon: PhosphorIcons.Paragraph.Bold
    },
    heading1: {
        name: 'Heading 1',
        icon: PhosphorIcons.TextHeading1.Bold
    },
    heading2: {
        name: 'Heading 2',
        icon: PhosphorIcons.TextHeading2.Bold
    },
    heading3: {
        name: 'Heading 3',
        icon: PhosphorIcons.TextHeading3.Bold
    },
    heading4: {
        name: 'Heading 4',
        icon: PhosphorIcons.TextHeading4.Bold
    },
    heading5: {
        name: 'Heading 5',
        icon: PhosphorIcons.TextHeading5.Bold
    },
    heading6: {
        name: 'Heading 6',
        icon: PhosphorIcons.TextHeading6.Bold
    },
    ul: {
        name: 'Unordered List',
        icon: PhosphorIcons.ListBullets.Bold
    },
    ol: {
        name: 'Ordered List',
        icon: PhosphorIcons.ListNumbers.Bold
    }
} as const;
