import { useRichTextFeatures } from 'components/Form/RichTextEditor/hooks/useRichTextFeatures';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

export const useRichTextIconSize = () => {
    /***** HOOKS *****/
    const hasFloatingToolbar = useRichTextFeatures('floating_toolbar');
    const isNotMobile = useAppViewport(['xs', 'sm'], true);

    /***** HOOK RESULTS *****/
    if (!hasFloatingToolbar && isNotMobile) {
        return 19;
    }
    return 23;
};
