import React from 'react';
import './_NXHelpBoxBullets.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _NXHelpBoxBullets: React.FC<Props> = ({ children }) => {
    return <ul className="NXHelpBox__bullets">{children}</ul>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
