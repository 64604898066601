import React from 'react';
import './_NXHelpBoxBulletItem.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _NXHelpBoxBulletItem: React.FC<Props> = ({ children }) => {
    return <li className="NXHelpBox__bulletItem">{children}</li>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
