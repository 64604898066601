export const validationRuleTypes = {
    ARRAY: 'array',
    BOOLEAN: 'boolean',
    FILE: 'file',
    IMAGE: 'image',
    IN: 'in',
    MAX: 'max',
    MIME_TYPES: 'mime_types',
    MIN: 'min',
    NULLABLE: 'nullable',
    NUMERIC: 'numeric',
    REQUIRED: 'required',
    REQUIRED_WITH: 'required_with',
    REQUIRED_UNLESS: 'required_unless',
    REQUIRED_IF: 'required_if',
    STRING: 'string',
    URL: 'url',
    EXISTS: 'exists',

    // Custom
    RICH_TEXT_CONTENT_MATCHES_TYPE: 'rich_text_content_matches_type'
} as const;
