/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { MutateOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function usePostNavigationMutation(serviceID: number, options: Omit<MutateOptions, 'mutationFn'> = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();

    /***** FUNCTIONS *****/
    function mutationFn(values: { [x: string]: any }) {
        const attributes = filterEndpointAttributeKeys(
            values,
            usePostNavigationMutation.acceptableKeys
        ) as KatanaAPI.Katana.ServiceID.Navigation.POST.Attributes;
        return KATANA_API.katana.service_id.navigation.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: usePostNavigationMutation.mutationKey,
        onSettled: () => {
            katanaQuery.serviceID.getNavigation.resetQueries(serviceID);
            katanaQuery.serviceID.service.invalidateQueries(serviceID);
        },
        onSuccess: page === 'setup' ? undefined : handleDefaultSuccessNotification,
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

usePostNavigationMutation.FORM_FIELD_KEYS = {
    CALL_TO_ACTION: 'call_to_action',
    COLOR: 'style.color',
    TRANSPARENT: 'style.transparent',
    VISIBLE: 'style.visible',
    HEADER_STYLE: 'style.header_style',
    SHOW_SOCIALS_IN_HEADER: 'show_socials_in_header',
    SHOW_SOCIALS_IN_FOOTER: 'show_socials_in_footer'
} as const;
usePostNavigationMutation.mutationKey = ['usePostNavigationMutation'] as const;
usePostNavigationMutation.acceptableKeys = Object.values(usePostNavigationMutation.FORM_FIELD_KEYS);

export { usePostNavigationMutation };
