/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PurchaseForm from '../forms/purchase';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { getProductDiscountOrBasePrice } from 'containers/email/methods';
import { activePromotion, truncateText } from 'utilities/methods/commonActions';
import { getEmailProducts } from '../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class PurchasePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entries: undefined,
            selectedEntry: undefined,
            products: undefined
        };

        this.showEditEntry = this.showEditEntry.bind(this);
        this.closeEditEntry = this.closeEditEntry.bind(this);
        this.addEntry = this.addEntry.bind(this);
        this.saveEntry = this.saveEntry.bind(this);
        this.removeEntry = this.removeEntry.bind(this);
    }

    showEditEntry(index) {
        this.setState({
            selectedEntry: index
        });
    }

    closeEditEntry() {
        this.setState({
            selectedEntry: undefined
        });
    }

    saveEntry(values) {
        const { entries, selectedEntry } = this.state;
        const { closeEditEntry } = this;

        const newEntries = entries;
        newEntries[selectedEntry] = {
            type: 'purchase',
            attributes: {
                ...values
            }
        };

        this.setState({
            entries: newEntries
        });

        closeEditEntry();
    }

    addEntry(values) {
        const { entries } = this.state;
        let newEntries;
        if (!entries) {
            newEntries = Array(1).fill();
            newEntries[newEntries.length - 1] = {
                type: 'purchase',
                attributes: {
                    ...values
                }
            };
        } else {
            newEntries = Array(entries.length).fill();
            Object.keys(newEntries).forEach((value) => {
                newEntries[value] = entries[value];
            });
            newEntries[newEntries.length] = {
                type: 'purchase',
                attributes: {
                    ...values
                }
            };
        }

        this.setState({
            entries: newEntries
        });
    }

    removeEntry(index) {
        const { entries } = this.state;
        const newEntries = entries.filter((e, i) => i !== index);
        this.setState({
            entries: newEntries
        });
    }

    componentDidMount() {
        const { getEmailProducts } = this.props;

        /***** MEGAMAY24 START *****/
        const promoCode = activePromotion('megamay2024') ? 'MAY24EMAIL' : null;
        /***** MEGAMAY24 END *****/

        getEmailProducts(promoCode);
    }

    componentDidUpdate(prevProps) {
        const { email_product_list_status, email_product_list_data, setShowDropdown, statuses } = this.props;

        if (statuses.status === 'success') {
            setShowDropdown?.(false);
        }

        if (email_product_list_status === 'success' && prevProps.email_product_list_status === 'loading') {
            const diskOptions = email_product_list_data.attributes.config.find((config) => config.name === 'Disk Space');
            const products = {
                planName: email_product_list_data.attributes.name,
                id: email_product_list_data.id,
                pricing: diskOptions.options[0].price,
                config: {
                    config_id: diskOptions.id,
                    option_id: diskOptions.options[0].id
                }
            };

            this.setState({
                products
            });
        }
    }

    render() {
        const { domain, email_product_list_status, email_product_list_data, statuses, submitRequest } = this.props;

        const { entries, selectedEntry, products } = this.state;

        const { addEntry, saveEntry, removeEntry, showEditEntry, closeEditEntry } = this;

        const handleMailboxEntryRender = (data, index) => {
            const productName = email_product_list_data.attributes.config
                .find((config) => config.name === 'Disk Space')
                .options.filter((option) => option.id === data.disk_space_option_id);
            const attributes = productName[0];

            return (
                <div key={index} className="entry">
                    <div className="entry__top">
                        <div className="entry__subtitle">{productName && attributes ? attributes.name : 'New Mailbox'}</div>
                        {selectedEntry === index ? (
                            <button type="button" className="entry__action close" onClick={() => closeEditEntry()}>
                                Close
                                <i className="icon icon-x" />
                            </button>
                        ) : (
                            <button type="button" className="entry__action" onClick={() => showEditEntry(index)}>
                                Edit
                                <i className="icon icon-edit" />
                            </button>
                        )}
                    </div>
                    <div className="entry__bottom">{truncateText(`${data.username}@${data.domain_name}`, 30, '...')}</div>
                    {selectedEntry === index ? (
                        <div className={`entry__form${selectedEntry === index ? ' selected' : ''}`}>
                            <PurchaseForm
                                meta={{
                                    form: `editMailboxesForm-${index}`,
                                    options: products,
                                    billing: handleBillingOptions(),
                                    productList: email_product_list_data,
                                    domain: domain ? domain : undefined
                                }}
                                data={data}
                                functions={{
                                    save: saveEntry
                                }}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            );
        };

        const handleCartItemRender = (data, index) => {
            const productName = email_product_list_data.attributes.config
                .find((config) => config.name === 'Disk Space')
                .options.filter((option) => option.id === data.config[0].option_id);
            const attributes = productName[0];

            return (
                <div key={index} className="cartOverview__row">
                    <div className="cartOverview__row--top">
                        <span className="title">{truncateText(data.email_address, 30, '...')}</span>
                        <button type="button" className="action" onClick={() => removeEntry(index)}>
                            Remove
                            <i className="icon icon-x" />
                        </button>
                    </div>
                    <div className="cartOverview__row--bottom">
                        <span className="desc">
                            {productName && attributes ? attributes.name : 'New Mailbox'} <strong>(billed {data.billing_cycle})</strong>
                        </span>
                        <span className="price">
                            ${productName && attributes ? getProductDiscountOrBasePrice(attributes.price[data.billing_cycle]) : ''} AUD
                        </span>
                    </div>
                </div>
            );
        };

        const handlePriceRender = () => {
            const priceArray = [];
            let sum = 0;
            if (entries && entries.length >= 1) {
                entries.forEach((entry) => {
                    const productName = email_product_list_data.attributes.config
                        .find((config) => config.name === 'Disk Space')
                        .options.filter((option) => option.id === entry.attributes.config[0].option_id);
                    priceArray.push(parseFloat(getProductDiscountOrBasePrice(productName[0].price[entry.attributes.billing_cycle])));
                });
            }
            sum = priceArray.reduce((partial_sum, a) => partial_sum + a, 0);
            return sum.toFixed(2);
        };

        const handleBillingOptions = () => {
            if (!email_product_list_data) return [];
            //Gets price of lowest tier
            const prices = email_product_list_data.attributes.config
                .find((config) => config.name === 'Disk Space')
                .options.find((option) => option.enabled === true).price;
            return Object.keys(prices).map((key) => {
                return { label: key, value: key };
            });
        };

        const handleLoadingStatus = () => {
            if (email_product_list_status === 'loading' || statuses.status === 'loading') {
                return 'loading';
            } else if (email_product_list_status === 'error' || statuses.status === 'error') {
                return 'error';
            }
            return 'success';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        switch (handleLoadingStatus()) {
            case 'loading':
                return <RequestLoader />;

            case 'success':
            case 'error':
                return (
                    <div
                        ref={(el) => {
                            this.scrollRef = el;
                        }}
                        className="emailPurchasePopup"
                    >
                        <div className="emailPurchasePopup__left">
                            <div className="configure__container">
                                <PurchaseForm
                                    meta={{
                                        form: `PurchaseForm`,
                                        options: products,
                                        billing: handleBillingOptions(),
                                        domain: domain ? domain : undefined
                                    }}
                                    functions={{
                                        submit: addEntry
                                    }}
                                />
                            </div>
                            {entries && entries.length >= 1 ? (
                                <div className="configure__ready">
                                    {entries.map((entry, index) => {
                                        // Need to split "email_address" into "username" and "domain_name" before passing the attributes back to the form
                                        const [username, domain_name] = (entry?.attributes?.email_address || '@').split('@');

                                        const existingAttributes = {
                                            ...entry.attributes,
                                            username,
                                            domain_name
                                        };

                                        delete existingAttributes.email_address;

                                        return handleMailboxEntryRender(existingAttributes, index);
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="emailPurchasePopup__right">
                            <div className="cartOverview">
                                <div className="cartOverview__details">
                                    {entries && entries.length >= 1 ? (
                                        entries.map((entry, index) => {
                                            return handleCartItemRender(entry.attributes, index);
                                        })
                                    ) : (
                                        <div className="cartOverview__empty">No mailboxes configured.</div>
                                    )}
                                </div>
                                {entries && entries.length >= 1 ? (
                                    <div className="cartOverview__overview">
                                        <div className="label">Amount Due Today</div>
                                        <div className="price">${handlePriceRender()} AUD</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="cartOverview__action">
                                    {entries && entries.length >= 1 ? (
                                        <SolidButton
                                            type="onClick"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                submitRequest(entries);
                                            }}
                                        >
                                            Confirm and generate invoice
                                        </SolidButton>
                                    ) : (
                                        <InactiveButton>Confirm and generate invoice</InactiveButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return '';
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        email_product_list_status: state.email.email_product_list_status,
        email_product_list_data: state.email.email_product_list_data
    };
};

const mapDispatchToProps = {
    getEmailProducts
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchasePopup));
