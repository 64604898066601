import type { JSONContent } from '@tiptap/core';
import type { Merge, Tagged } from 'type-fest';
import { stringToJSON } from 'utilities/zod/stringToJSON';
import { z } from 'zod';

export type RichTextMarkdown = z.infer<typeof richTextMarkdownData>;
export type RichTextTipTapJSON = Merge<z.infer<typeof richTextTipTapDataJSON>, { content: TipTapDocumentJSON }>;
export type RichTextTipTapObject = Merge<z.infer<typeof richTextTipTapDataObject>, { content: TipTapDocument }>;
export type RichTextTipTapJSONParsed = RichTextTipTapObject;
export type RichTextData = RichTextMarkdown | RichTextTipTapJSON;
export type TipTapContent = z.infer<typeof tipTapContent>;
export type TipTapDocument = JSONContent | JSONContent[];
export type TipTapDocumentJSON = Tagged<string, 'JSON', TipTapDocument>;
export type RichTextContent = string | TipTapDocument | null;

const richTextMarkdownData = z.object({
    type: z.literal('markdown'),
    content: z.string().nullable()
});

const tipTapContent: z.ZodType<JSONContent> = z.record(z.unknown());

const tipTapDocument = z.object({
    type: z.literal('doc'),
    content: z.union([z.array(tipTapContent), tipTapContent])
});
const tipTapDocumentJSON = stringToJSON().pipe(tipTapDocument);

const richTextTipTapDataObject = z.object({
    type: z.literal('tiptap'),
    content: tipTapDocument
});

export const richTextTipTapDataJSON = z.object({
    type: z.literal('tiptap'),
    content: tipTapDocumentJSON
});

const richTextDataZod = z.union([richTextMarkdownData, richTextTipTapDataJSON]);

export function isTipTapContent(value: unknown): value is TipTapContent {
    return tipTapContent.safeParse(value).success;
}

export function isRichTextMarkdown(value: unknown): value is RichTextMarkdown {
    return richTextMarkdownData.safeParse(value).success;
}

export function isRichTextTipTapJSON(value: unknown): value is RichTextTipTapJSON {
    return richTextTipTapDataJSON.safeParse(value).success;
}

export function parseRichTextTipTapJSON(value: RichTextTipTapJSON) {
    return richTextTipTapDataJSON.parse(value) as RichTextTipTapJSONParsed;
}

export function isRichTextTipTapObject(value: unknown): value is RichTextTipTapObject {
    return richTextTipTapDataObject.safeParse(value).success;
}

export function isRichTextData(value: unknown): value is RichTextData {
    return richTextDataZod.safeParse(value).success;
}

export function isTipTapDocumentJSON(value: unknown): value is TipTapDocumentJSON {
    return tipTapDocumentJSON.safeParse(value).success;
}
