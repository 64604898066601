/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { DisplayOnlyWrapper } from 'containers/katana/formFields/file/inputTypes/display_only/DisplayOnlyWrapper';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFileFormFieldDisplayOnly: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, type, validationFunctions } = property;

    /***** RENDER *****/
    return (
        <Field
            name={key}
            label={name}
            component={DisplayOnlyWrapper}
            property={property}
            type={type}
            singleFile
            validate={validationFunctions}
        />
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldDisplayOnly };
