import type { Editor } from '@tiptap/core';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type MarkdownExtractorPluginProps = {
    onChange: (markdown: string) => void;
};
type HandleChangeValue = { editor: Editor };

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MarkdownExtractorPlugin: React.FC<MarkdownExtractorPluginProps> = ({ onChange }) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();

    /***** FUNCTIONS *****/
    const handleOnChange = ({ editor }: HandleChangeValue) => {
        const markdownOutput = editor.storage.markdown.getMarkdown();
        onChange(markdownOutput);
    };

    /***** EFFECTS *****/
    useEffect(() => {
        if (!editor) return;
        editor.on('update', handleOnChange);
        return () => {
            editor.off('update', handleOnChange);
        };
    }, []);

    /***** RENDER *****/
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
