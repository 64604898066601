/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

/*   ACTIONS
 *****************************************************/
import { APP_UPDATE_USER } from 'App/action';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { useCheckTokenData } from 'utilities/hooks/redux/useCheckTokenData';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useRegisterSiteMutation(serviceID: number) {
    /***** HOOKS *****/
    const app_check_token_data = useCheckTokenData();
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    function mutationFn(attributes: Partial<KatanaAPI.Katana.ServiceID.Register.POST.Attributes>) {
        return KATANA_API.katana.service_id.register.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: () => {
            katanaQuery.serviceID.service.invalidateQueries(serviceID);

            // Manually set "is_user_detail_completed" to true so we don't have to refetch check token data
            // We might have to consider refetching the user here too so we are confident this is correct
            dispatch({
                type: APP_UPDATE_USER,
                app_check_token_data: {
                    ...app_check_token_data,
                    is_user_detail_completed: true
                }
            });
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
