import classNames from 'classnames';
import { useRichTextFeatures } from 'components/Form/RichTextEditor/hooks/useRichTextFeatures';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { IconifyIconNamespace } from 'components/Icons/types';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import React from 'react';
import { useClickAwayOutsideRefOnly } from 'utilities/hooks/useClickAwayOutsideRefOnly';
import './_ToolbarDropdown.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children?: React.ReactNode;
    disabled?: boolean;
    isOpen: boolean;
    className?: string;
    setIsOpen: (isOpen: boolean) => void;
    ariaLabel: string;
    Icon?: IconifyIconNamespace.Component;
    buttonText?: string;
    orientation?: 'horizontal' | 'vertical';
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ToolbarDropdown: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    disabled,
    className,
    children,
    ariaLabel,
    Icon,
    buttonText,
    orientation = 'vertical'
}) => {
    /***** HOOKS *****/
    const toolbarRef = React.useRef<HTMLDivElement>(null);

    const iconSize = useRichTextIconSize();
    const isFloatingToolbar = useRichTextFeatures('floating_toolbar');

    useClickAwayOutsideRefOnly([toolbarRef], () => setIsOpen(false));

    /***** FUNCTIONS *****/
    function handleOnClick() {
        setIsOpen(!isOpen);
    }

    /***** RENDER HELPERS *****/
    const hasIcon = !!Icon;

    /***** RENDER *****/
    return (
        <div ref={toolbarRef}>
            <Flex
                justify="center"
                items="center"
                className={classNames(
                    'ToolbarDropdown',
                    {
                        'ToolbarDropdown--isOpen': isOpen,
                        'ToolbarDropdown--disabled': disabled,
                        [`ToolbarDropdown--orientation-${orientation}`]: orientation
                    },
                    className
                )}
            >
                <Grid inject columns={hasIcon ? 'auto 1fr auto' : '1fr auto'} alignItems--center gap={1}>
                    <button
                        type="button"
                        className="ToolbarDropdown__toggleButton"
                        disabled={disabled}
                        onClick={handleOnClick}
                        aria-label={ariaLabel}
                    >
                        {hasIcon && <Icon size={iconSize} />}
                        {buttonText && !isFloatingToolbar && (
                            <Text size--s semiBold>
                                {buttonText}
                            </Text>
                        )}
                        <PhosphorIcons.Chevron state={isOpen ? 'up' : 'down'} size={iconSize - 2} />
                    </button>
                </Grid>

                {/* We should eventually make it so that this menu stays within the bounds of the viewport */}
                {isOpen && (
                    <Border all className="ToolbarDropdown__optionsWrapper">
                        {children}
                    </Border>
                )}
            </Flex>
        </div>
    );
};
