// Mutations will go here
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { getSiteList } from 'containers/katana/queries/getSiteList';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { API } from 'utilities/api/services';

/**********************************************************************************************************
 *   Cancel a service
 **********************************************************************************************************/
function createMutationKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.cancel(serviceID);
}

function _useMutation(serviceID: KatanaNamespace.ServiceID) {
    return useMutation({
        mutationKey: createMutationKey(serviceID),
        mutationFn: (attributes) => API.service.post.cancel(serviceID, attributes),
        onSuccess: () => {
            service.optimistic.set(serviceID, 'data.attributes.status', 'pending cancellation');
            getSiteList.optimistic.setWith(undefined, 'data', (sitePage) => {
                for (const site of sitePage) {
                    if (site.id === serviceID) {
                        site.attributes.status = 'pending cancellation';
                        break;
                    }
                }

                return sitePage;
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID(serviceID)
            });
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.list()
            });
        }
    });
}

export const cancel = Object.freeze({
    useMutation: _useMutation,
    createMutationKey
});
