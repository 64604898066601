/**********************************************************************************************************
 *   Katana Update Section Order
 **********************************************************************************************************/

import type { QueryKey } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { applyNewSectionSort } from 'containers/katana/queries/methods/applyNewSectionSort';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';

function createMutationKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.sections.reorder(serviceID) as QueryKey;
}

function _useMutation(serviceID: KatanaNamespace.ServiceID, options = {}) {
    async function mutationFn(sectionIDs: KatanaNamespace.SectionID[]) {
        const cancelQueries = [katanaQuery.serviceID.sections.cancelQueries(serviceID), katanaQuery.serviceID.service.cancelQueries(serviceID)];
        await Promise.allSettled(cancelQueries);

        sections.optimistic.setWith(serviceID, 'data', (oldData) => {
            return applyNewSectionSort(sectionIDs, oldData);
        });
        service.optimistic.setWith(serviceID, 'data.sections', (oldData) => {
            return applyNewSectionSort(sectionIDs, oldData);
        });
        return KATANA_API.katana.site.service_id.sections.reorder.POST({ serviceID, sectionIDs });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: createMutationKey(serviceID),
        onSuccess: () => {
            katanaQuery.serviceID.sections.invalidateQueries(serviceID);
        },
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const reorderSections = Object.freeze({
    useMutation: _useMutation,
    createMutationKey
});
