/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { z } from 'zod';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainOverview from 'containers/services/modules/domains';
import EmailOverview from 'containers/services/modules/email';
import GoogleOverview from 'containers/services/modules/gsuite';
import HostingOverview from 'containers/services/modules/hosting';
import { KatanaServicesList } from 'containers/services/modules/katanaServicesList';
import { Microsoft365Table } from 'containers/services/modules/ms365';
import { SSLTable } from 'containers/services/modules/ssl';
import { VPSTable } from 'containers/services/modules/vps';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesDomainRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'domains',
    component: DomainOverview,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesBulkDomainRoute = createRoute({
    getParentRoute: () => MyServicesDomainRoute,
    path: 'bulk',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesBulkChildDomainRoute = createRoute({
    getParentRoute: () => MyServicesBulkDomainRoute,
    path: '$section',
    component: DomainOverview,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesHostingRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'hosting',
    component: HostingOverview,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesEmailRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'email-hosting',
    component: EmailOverview,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesGoogleRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'google',
    component: GoogleOverview,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesMicrosoftRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'microsoft-365',
    component: Microsoft365Table,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesVPSRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'vps',
    component: VPSTable,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesVIPSitesRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'vip-sites',
    component: KatanaServicesList,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: z.object({ 'scroll-to-banner': z.boolean().optional() })
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesSSLRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'ssl',
    component: SSLTable,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});
