/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { validationMaximum } from 'utilities/methods/form/validations/validationMaximum';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function formFieldsMaxRuleParser(validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) {
    return (value: any) => {
        return validationMaximum(value, validation?.value);
    };
}
