/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineTag from 'components/Tags/OutlineTag';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';
import type { ServiceNamespace } from 'containers/services/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaServiceListEntryTag: React.FC<{
    status: ServiceNamespace.Status;
    site_status: KatanaNamespace.SiteStatus;
}> = ({ status, site_status }) => {
    /***** RENDER HELPERS *****/
    const isPendingCancellation = status === 'pending cancellation';

    /***** RENDER *****/
    if (isPendingCancellation) {
        return <OutlineTag color="info">Pending Cancellation</OutlineTag>;
    }
    switch (site_status) {
        case 'in-progress':
            return <OutlineTag color="notice">In Progress</OutlineTag>;

        case 'published':
            return <OutlineTag color="confirm">Published</OutlineTag>;

        case 'unpublished':
            return <OutlineTag color="confirm">Published</OutlineTag>;

        case 'suspended':
            return <OutlineTag color="warn">Suspended</OutlineTag>;

        default:
            return <OutlineTag color="primary">Unconfigured</OutlineTag>;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default KatanaServiceListEntryTag;
