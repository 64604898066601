import { useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaDataUtils } from 'containers/katana/queries/methods/dataUtils';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    serviceID: KatanaNamespace.ServiceID;
    katanaSectionID?: KatanaNamespace.SectionDefinitionID;
};

type MutationFnParams = {
    sectionID: KatanaNamespace.SectionID;
    attributes: KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.ModifiedTypes.PartialData;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation({ serviceID, katanaSectionID }: Params) {
    const { page } = useKatanaParams();
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, katanaSectionID });

    function mutationFn({ sectionID, attributes }: MutationFnParams) {
        const updatedAttributes = handleModifyEntireAttributeObject(attributes);
        const hasName = katanaDataUtils.section.hasName(updatedAttributes);
        const hasProperties = katanaDataUtils.section.hasProperties(updatedAttributes);

        if (hasName || hasProperties) {
            sections.optimistic.setWith(serviceID, 'data', (sections) => {
                for (const section of sections) {
                    if (section.id === sectionID) {
                        if (hasName) {
                            section.name = updatedAttributes.name;
                        }
                        if (hasProperties) {
                            section.properties = updatedAttributes.properties;
                        }
                        break;
                    }
                }

                return sections;
            });

            service.optimistic.setWith(serviceID, 'data.sections', (sections) => {
                for (const section of sections) {
                    if (section.id === sectionID) {
                        if (hasName) {
                            section.name = updatedAttributes.name;
                        }
                        if (hasProperties) {
                            // If the types for this get messed up it could be because the union of the ARTAH type is not correct, a temporary workaround is delete any of the artah type files for this that are not complete
                            section.properties = updatedAttributes.properties;
                        }
                    }
                }
                return sections;
            });
        }

        return KATANA_API.katana.site.service_id.section.section_id.PUT({ serviceID, sectionID, attributes: updatedAttributes });
    }

    return useMutation({
        mutationFn,
        onError: handleDefaultErrorNotification,
        onSuccess: (response) => {
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }
        },
        onSettled: () => {
            service.invalidateQueries(serviceID);
            sections.invalidateQueries(serviceID);
        }
    });
}

export const updateSection = Object.freeze({
    useMutation: _useMutation
});
