/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InstagramConnectCaveat } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/InstagramConnectCaveat';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SocialsLoginCaveatProps = {
    socialType: 'instagram';
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SocialsLoginCaveat: React.FC<SocialsLoginCaveatProps> = ({ socialType, property }) => {
    /***** RENDER *****/
    switch (socialType) {
        case 'instagram':
            return <InstagramConnectCaveat property={property} />;

        default:
            return <Text>Unsupported social login type {socialType}</Text>;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
