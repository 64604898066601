import { Store } from '@tanstack/react-store';
import type { Editor } from '@tiptap/react';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import { createContext } from 'react';

type Params = {
    features: RichTextEditorNamespace.FeatureSet[];
    editor: Editor | null;
};

export const RichTextEditorContext = createContext<Store<RichTextEditorNamespace.ContextState>>(
    new Store<RichTextEditorNamespace.ContextState>({
        isLinkEditMode: false,
        rootElement: null,
        features: [],
        editor: null,
        contentElement: null,
        canShowFloatingToolbar: true
    })
);

export function newRichTextEditorTanstackStore({ features = [], editor }: Params) {
    return new Store<RichTextEditorNamespace.ContextState>({
        isLinkEditMode: false,
        rootElement: null,
        features,
        editor,
        contentElement: null,
        canShowFloatingToolbar: true
    });
}

export const richTextFeatureEnum = {
    // UNDO: 'undo',
    // REDO: 'redo',
    NO_NEW_LINE: 'no_new_line',
    LINK: 'link',
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    STRIKETHROUGH: 'strikethrough',
    HIGHLIGHT: 'highlight',
    HEADING: 'heading',

    NUMBERED_LIST: 'ol',
    UNORDERED_LIST: 'ul',

    // CLEAR: 'clear'
    FLOATING_TOOLBAR: 'floating_toolbar'
    // ALIGNMENT: 'alignment'
} as const;

// These are not direct features that can be toggled on/off, but are used to enable other features like value interpretation
export const indirectRichTextFeaturesEnum = {
    MARKDOWN: 'markdown',
    TIPTAP: 'tiptap'
} as const;

export const richTextFeatures = Object.values(richTextFeatureEnum);
export const indirectRichTextFeatures = Object.values(indirectRichTextFeaturesEnum);
