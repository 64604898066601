import { DialogNotification } from 'components/Notifications/DialogNotification';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PropertyEmailRequirementWarning: KatanaNamespace.PropertyComponent = (props) => {
    const { property } = props;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_service_data } = katanaQuery.serviceID.service.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const isContactUsRequestedDetails = property?.key.includes('properties.requested_details');
    const hasBusinessEmail = get_service_data?.business?.email ? true : false;

    /***** RENDER *****/
    if (!hasBusinessEmail && isContactUsRequestedDetails) {
        return (
            <Padding y={2}>
                <DialogNotification type="warning" noMargin>
                    <Text bold black size--xss lead--s>
                        This feature requires an email to work. To show the contact form on your site, please add your business email on the contact
                        information page.
                    </Text>
                </DialogNotification>
            </Padding>
        );
    }
    return null;
};
