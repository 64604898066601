import * as Sentry from '@sentry/react';
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _RichTextEditorTextArea as RichTextEditorTextArea } from 'components/Form/RichTextEditor/_RichTextEditorTextArea';
import type { RichTextEditorTextAreaReduxFormNamespace } from 'components/Form/RichTextEditor/_RichTextEditorTextArea/wrappers/reduxform/types';
import { formatRichTextData } from 'components/Form/RichTextEditor/methods/formatRichTextData';
import { isDefaultTipTapDocument } from 'components/Form/RichTextEditor/methods/isDefaultTipTapDocument';
import type { RichTextContent } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import {
    isRichTextMarkdown,
    isRichTextTipTapJSON,
    isRichTextTipTapObject,
    parseRichTextTipTapJSON
} from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RichTextEditorTextAreaReduxForm: React.FC<RichTextEditorTextAreaReduxFormNamespace.Props> = (props) => {
    const { input, meta, label, rows, features, initialValue, formItemInnerProps = {} } = props;
    const { name, value, onChange, onBlur } = input;

    /***** HOOKS *****/
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    const onChangeTransform = useCallback(
        (value: RichTextContent) => {
            if (!isDefaultTipTapDocument(value)) {
                dispatch(touch(meta.form, name));
            }

            const formattedValue = formatRichTextData(value, features);
            onChange(formattedValue);
        },
        [onChange]
    );

    function onBlurTransform(value: RichTextContent) {
        onBlur(formatRichTextData(value, features));
    }

    /***** RENDER HELPERS *****/
    const valueTransform = useMemo(() => {
        if (isRichTextTipTapObject(value)) {
            return value.content ?? '';
        }
        if (isRichTextMarkdown(value)) {
            return value.content ?? '';
        }
        if (isRichTextTipTapJSON(value)) {
            return parseRichTextTipTapJSON(value)?.content || '';
        }

        if (_.isString(value)) {
            return value;
        }

        Sentry.captureException(new Error('Invalid value passed to `_RichTextEditorTextAreaReduxForm`'), { data: { props, input } });
        return '';
    }, [value]);

    /***** RENDER *****/
    return (
        <FormItem
            // Want to keep these here to help remind that these are normally required but are not implemented in _RichTextEditorTextAreaReduxForm
            // disabled={disabled}
            name={name}
        >
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} {...formItemInnerProps}>
                <RichTextEditorTextArea
                    // Want to keep these here to help remind that these are normally required but are not implemented in _RichTextEditorTextAreaReduxForm
                    // disabled={disabled}
                    initialValue={initialValue}
                    value={valueTransform}
                    rows={rows}
                    onChange={onChangeTransform}
                    onBlur={onBlurTransform}
                    features={features}
                />
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
