import { API_BASE_URL } from 'App/consts';
import type { UseKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/types';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { isAttachmentDataAttachment, isAttachmentDataLink } from 'containers/katana/queries/methods/attachmentData';
import { has } from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetKatanaFileUploadValue(value: UseKatanaFileUpload.Values) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        if (!Array.isArray(value) && typeof value !== 'string' && has(value, 'url')) {
            return value.url;
        }

        if (isAttachmentDataAttachment(value) && katanaServiceId) {
            return `${API_BASE_URL}/api/katana/${katanaServiceId}/uploads/${value.attachment.id}/file`.replace(/\/\/api/g, '/api');
        }

        if (isAttachmentDataLink(value)) {
            return value.url;
        }

        if (Array.isArray(value) && value.length) {
            return value?.[0].url;
        } else if (typeof value === 'string') {
            return value;
        }

        return '';
    }, [katanaServiceId, value]);
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
