import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { MouseEventHandler } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InsertLinkAction = () => {
    /***** HOOKS *****/
    const [, setIsLinkEditMode] = useRichTextEditorContext('isLinkEditMode', false);
    const editor = useRichTextEditor();
    const iconSize = useRichTextIconSize();

    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        setIsLinkEditMode(true);
    };

    /***** RENDER HELPERS *****/
    const isLink = editor?.isActive('link');

    /***** RENDER *****/
    return (
        <ToolbarButton ariaLabel={isLink ? 'Update Link' : 'Insert Link'} onClick={onClick} isActive={isLink}>
            <PhosphorIcons.Link.Bold size={iconSize} black={isLink} />
        </ToolbarButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
