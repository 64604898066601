/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CardSelectGrid as CardSelectGrid } from 'components/Form/CardSelectGrid/_CardSelectGrid';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CardSelectGridHookForm: React.FC<CardSelectGridNamespace.HookForm.Props> = ({
    description,
    label,
    name,
    /**
     * CardSelectGrid Props
     */
    ...props
}) => {
    /***** HOOKS *****/
    const { field } = useController({ name });

    /***** FUNCTIONS *****/
    const handleChange = (e: CardSelectGridNamespace.SelectCard.OnChangeValue) => {
        field.onChange(e);
        field.onBlur();
    };

    /***** RENDER *****/
    return (
        <FormItem
            name={name}
            className={classNames({
                'CardSelectGrid--hasDescription': description
            })}
        >
            <FormLabel htmlFor={name}>{label}</FormLabel>
            {description}
            <FormItemInner.HookForm name={name} noBackground fullWidth>
                <CardSelectGrid {...props} onChange={handleChange} value={field?.value} />
            </FormItemInner.HookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
