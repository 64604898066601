/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createModuleStore } from 'utilities/methods/createModuleStore';

export const EmailHostingModuleStore = createModuleStore(
    {
        general: {
            mailboxes: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/general',
                hash: 'mailboxes',
                label: 'Mailboxes'
            },
            addMailboxes: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/general',
                hash: 'add-mailbox',
                label: 'Add Mailboxes'
            },
            overview: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general',
                hash: 'overview',
                label: 'Overview'
            },
            serviceDetails: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general',
                hash: 'details',
                label: 'Service Details'
            },
            diskUsage: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general',
                hash: 'disk-usage',
                label: 'Disk Usage'
            },
            billingCycle: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general',
                hash: 'billing',
                label: 'Billing Cycle'
            }
        },
        tools: {
            manageAliases: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/tools',
                hash: 'aliases',
                label: 'Manage Aliases'
            },
            spamFilteringLog: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/tools',
                hash: 'spam-filtering-log',
                label: 'Email Spam Filtering Log'
            }
        },
        admin: {
            activeSync: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/mailbox/$mailboxId/admin',
                hash: 'activesync',
                label: 'ActiveSync'
            },
            delete: {
                enabled: true,
                to: '/my-services/email-hosting/$groupId/mailbox/$mailboxId/admin',
                hash: 'delete',
                label: 'Delete Mailbox'
            }
        }
    },
    useModuleConditions
);

/*
 * CONDITIONS START
 */
function useModuleConditions(_store: any) {
    const store = createModuleStore.castHookProps<typeof EmailHostingModuleStore>(_store);

    /***** HOOKS *****/
    const email_information_mailbox_data = useSelector((state: any) => state.email.email_information_mailbox_data);
    const { mailboxId } = useParams({ strict: false });

    const mailboxRoute = mailboxId ? ('mailbox/$mailboxId/' as const) : ('' as const);

    /***** CONDITIONAL UPDATES *****/
    useEffect(() => {
        store.batch(() => {
            // Condition Updates
            store.updateChangedStoreKey('general.mailboxes.enabled', !mailboxId);
            store.updateChangedStoreKey('general.addMailboxes.enabled', !mailboxId);
            store.updateChangedStoreKey('general.overview.enabled', !!mailboxId);
            store.updateChangedStoreKey('general.diskUsage.enabled', !!mailboxId);
            store.updateChangedStoreKey('general.billingCycle.enabled', Boolean(mailboxId && email_information_mailbox_data));
            store.updateChangedStoreKey('tools.manageAliases.enabled', !mailboxId);
            store.updateChangedStoreKey('admin.activeSync.enabled', !!mailboxId);
            store.updateChangedStoreKey('admin.delete.enabled', !!mailboxId);
            store.updateChangedStoreKey('tools.spamFilteringLog.enabled', !mailboxId);

            // Path Updates
            store.updateChangedStoreKey('general.serviceDetails.to', `/my-services/email-hosting/$groupId/${mailboxRoute}general`);
        });
    });
}
