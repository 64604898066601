import Text from 'components/Utils/Text';
import type { TextNamespace } from 'components/Utils/Text/_Text/types';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PropertyRenderText: KatanaNamespace.PropertyComponent = (props) => {
    const { property } = props;
    const { caveats } = property;

    /***** RENDER HELPERS *****/
    const customTextElement = useGetCaveat(CaveatTypes.CUSTOM_TEXT, caveats) as TextNamespace.Props | null;

    /***** RENDER *****/
    if (!customTextElement) return null;
    return <Text {...customTextElement} />;
};
