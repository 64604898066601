import { navigationLabelsStore } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/consts';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { SocialMediaLinksContentModule } from 'containers/katana/containers/contentEditorModules/socialMediaLinks';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationItemsMutation } from 'containers/katana/queries/usePostNavigationItemsMutation';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleNavigationSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    const { katanaServiceId } = useKatanaParams();
    const { submitSiteData } = useSiteDataPreviewValues();

    /***** QUERIES *****/
    const { data: get_katana_socials_data } = katanaQuery.serviceID.getSocials.useQuery(katanaServiceId);
    const { mutateAsync: mutateAsyncPostNavigationItems } = usePostNavigationItemsMutation(katanaServiceId);
    const { mutateAsync: mutateAsyncPostNavigation } = usePostNavigationMutation(katanaServiceId);
    const { mutateAsync: mutateAsyncUpdateSocialLinks } = useUpdateSocialLinksMutation(katanaServiceId);
    const { mutateAsync: mutateAsyncDeleteSocialType } = katanaQuery.serviceID.deleteSocialType.useMutation(katanaServiceId);
    const { mutateAsync: mutateUpdateKatanaSiteSection } = katanaQuery.serviceID.updateSection.useMutation({ serviceID: katanaServiceId });

    /***** HOOK RESULTS *****/
    return async function handleSubmit(values: { [x: string]: any }) {
        const promises = [mutateAsyncPostNavigationItems(values), mutateAsyncPostNavigation(values)];

        if (_.has(values, SocialMediaLinksContentModule.formFields.SOCIALS)) {
            const socials = values[SocialMediaLinksContentModule.formFields.SOCIALS] as KatanaAPI.Social[];
            const filteredSocials = socials.filter(Boolean).filter(({ url }) => Boolean(url));
            /**
             * When the list of filtered socials is updated and it still has socials in it we can make a post request to update the list of socials
             * If the list is empty we can't make the request because the attributes key that CCP is expecting can't be an empty array
             *  Instead we need to fire a delete request for each social type that previously existed
             */
            if (filteredSocials.length) {
                promises.push(mutateAsyncUpdateSocialLinks(filteredSocials));
            } else {
                _.forEach(get_katana_socials_data, ({ type }) => {
                    promises.push(mutateAsyncDeleteSocialType(type));
                });
            }
        }

        if (navigationLabelsStore.state.length) {
            navigationLabelsStore.state.forEach((labelEntry) => {
                const { sectionID, name } = labelEntry;
                const mutation = mutateUpdateKatanaSiteSection({ sectionID, attributes: { name } });
                promises.push(mutation);
            });
        }

        submitSiteData(promises);

        return Promise.all(promises)
            .then(() => {
                performRouting();
                navigationLabelsStore.setState(() => []);
            })
            .catch((e) => {
                return e;
            });
    };
}
