/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { connect } from 'react-redux';

// Carousel
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DealsCarousel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DealsCarouselProps = {
    // The cards to feed to the carousel
    cards: React.ReactNode[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class DealsCarousel extends Component<DealsCarouselProps> {
    constructor(props) {
        super(props);

        this.renderCarousel = this.renderCarousel.bind(this);
    }

    renderCarousel(cards) {
        return (
            <Slider
                dots
                customPaging={(i) => <div></div>}
                arrows={false}
                autoplay
                autoplaySpeed={5000}
                infinite
                speed={500}
                slidesToShow={3}
                slidesToScroll={1}
                responsive={[
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]}
            >
                {cards}
            </Slider>
        );
    }

    /************** LIFECYCLE METHODS **************/

    render() {
        const { app_viewport, cards } = this.props;
        const { renderCarousel } = this;

        const renderCards = () =>
            cards.map((card, index) => (
                <div key={index} className="DealsCarousel__card">
                    {card}
                </div>
            ));

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="DealsCarousel">
                {['xs', 'sm', 'md'].includes(app_viewport) ? (
                    renderCarousel(renderCards())
                ) : (
                    <div className="DealsCarousel__cards">{renderCards()}</div>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default connect((state) => ({
    app_viewport: state.app.app_viewport
}))(DealsCarousel);
