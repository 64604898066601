import type { KatanaNamespace } from 'containers/katana/types';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

type ServiceAndSectionDefinitionID = { serviceID: KatanaNamespace.ServiceID; sectionDefinitionID: KatanaNamespace.SectionDefinitionID };
type ServiceAndPageID = { serviceID: KatanaNamespace.ServiceID; pageID: KatanaNamespace.PageID };
type ServiceAndPageIDAndSectionID = { serviceID: KatanaNamespace.ServiceID; pageID: KatanaNamespace.PageID; sectionID: KatanaNamespace.SectionID };

export const katanaQueryKeys = {
    katana: Object.assign(() => [...createBaseQueryKey(), 'katana'] as const, {
        service: Object.assign(() => [...katanaQueryKeys.katana(), 'service'] as const, {
            ID: Object.assign((serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service(), serviceID] as const, {
                cancel: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'cancel'] as const,
                    {}
                ),
                sections: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'sections'] as const,
                    {
                        ID: Object.assign(
                            ({ serviceID, sectionID }: { serviceID: KatanaNamespace.ServiceID; sectionID: KatanaNamespace.SectionID | null }) =>
                                [...katanaQueryKeys.katana.service.ID.sections(serviceID), sectionID] as const,
                            {}
                        ),
                        add: Object.assign(
                            (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'add'] as const,
                            {}
                        ),
                        reorder: Object.assign(
                            (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'reorder'] as const,
                            {}
                        )
                    }
                ),
                pages: Object.assign((serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'pages'] as const, {
                    ID: Object.assign(
                        (params: ServiceAndPageID) => [...katanaQueryKeys.katana.service.ID.pages(params.serviceID), params.pageID] as const,
                        {
                            sections: Object.assign(
                                (params: ServiceAndPageID) => [...katanaQueryKeys.katana.service.ID.pages.ID(params), 'sections'] as const,
                                {
                                    ID: Object.assign(
                                        ({ sectionID, ...rest }: ServiceAndPageIDAndSectionID) =>
                                            [...katanaQueryKeys.katana.service.ID.pages.ID.sections(rest), sectionID] as const,
                                        {}
                                    )
                                }
                            )
                        }
                    )
                }),
                dns: Object.assign((serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'dns'] as const, {}),
                domainOptions: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'domain_options'] as const,
                    {}
                ),
                navigation: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'navigation'] as const,
                    {
                        items: Object.assign(
                            (serviceID: KatanaNamespace.ServiceID) =>
                                [...katanaQueryKeys.katana.service.ID.navigation(serviceID), 'navigation'] as const,
                            {}
                        )
                    }
                ),
                preview: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'preview'] as const,
                    {
                        preset: Object.assign(
                            (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'preset'] as const,
                            {
                                ID: Object.assign(
                                    ({ serviceID, presetID, site }: KatanaAPI.Katana.ServiceID.Preview.Preset.POST.Params) =>
                                        [...katanaQueryKeys.katana.service.ID.preview.preset(serviceID), presetID, JSON.stringify(site)] as const,
                                    {}
                                )
                            }
                        ),
                        sectionDefinition: Object.assign(
                            (serviceID: KatanaNamespace.ServiceID) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'section-definition'] as const,
                            {
                                ID: Object.assign(
                                    ({ serviceID, sectionDefinitionID }: ServiceAndSectionDefinitionID) =>
                                        [...katanaQueryKeys.katana.service.ID.preview.sectionDefinition(serviceID), sectionDefinitionID] as const,
                                    {}
                                )
                            }
                        ),

                        section: Object.assign(
                            (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'section'] as const,
                            {
                                ID: Object.assign(
                                    ({
                                        serviceID,
                                        sectionID,
                                        ...attributes
                                    }: KatanaAPI.Katana.ServiceID.Preview.Section.SectionID.POST.QueryParams) =>
                                        [...katanaQueryKeys.katana.service.ID.preview.section(serviceID), sectionID, attributes] as const,
                                    {}
                                )
                            }
                        ),
                        site: Object.assign(
                            ({ serviceID, attributes }: KatanaAPI.Katana.ServiceID.Preview.Site.POST.Params) =>
                                [...katanaQueryKeys.katana.service.ID.preview(serviceID), 'site', attributes] as const,
                            {}
                        )
                    }
                ),
                user: Object.assign((serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'user'] as const, {
                    socials: Object.assign(
                        (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID.user(serviceID), 'socials'] as const,
                        {
                            ID: Object.assign(
                                ({ serviceID, socialID }: { serviceID: KatanaNamespace.ServiceID; socialID?: number }) =>
                                    [...katanaQueryKeys.katana.service.ID.user.socials(serviceID), socialID] as const,
                                {}
                            )
                        }
                    )
                }),
                socials: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'socials'] as const,
                    {}
                ),
                info: Object.assign((serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'info'] as const, {}),
                business: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'business'] as const,
                    {}
                ),
                uploadFile: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'upload-file'] as const,
                    {}
                ),
                serviceDetails: Object.assign(
                    (serviceID: KatanaNamespace.ServiceID) => [...katanaQueryKeys.katana.service.ID(serviceID), 'service-details'] as const,
                    {}
                )
            }),
            list: Object.assign(() => [...katanaQueryKeys.katana(), 'list'] as const, {
                filters: Object.assign(
                    (filters?: KatanaAPI.Katana.GET.Filters) =>
                        filters ? ([...katanaQueryKeys.katana.service.list(), filters] as const) : katanaQueryKeys.katana.service.list(),
                    {}
                )
            })
        }),
        file: Object.assign(() => [...katanaQueryKeys.katana(), 'file'] as const, {
            ID: Object.assign(
                ({ serviceID, uploadID }: KatanaAPI.Katana.ServiceID.Uploads.UploadID.file.GET.Params) =>
                    [...katanaQueryKeys.katana.file(), serviceID, uploadID] as const,
                {}
            )
        }),
        trialDomains: Object.assign(() => [...katanaQueryKeys.katana(), 'trial_domains'] as const, {}),
        domains: Object.assign((filters: KatanaAPI.Katana.domains.GET.Filters) => [...katanaQueryKeys.katana(), 'domains', filters] as const, {}),
        checkDomain: Object.assign((domain: string) => [...katanaQueryKeys.katana(), 'check_domain', domain] as const, {}),
        meta: Object.assign(() => [...katanaQueryKeys.katana(), 'meta'] as const, {
            themePresets: () => [...katanaQueryKeys.katana.meta(), 'theme_presets'] as const,
            socials: () => [...katanaQueryKeys.katana.meta(), 'socials'] as const,
            sections: () => [...katanaQueryKeys.katana.meta(), 'sections'] as const,
            colours: () => [...katanaQueryKeys.katana.meta(), 'colours'] as const,
            fonts: () => [...katanaQueryKeys.katana.meta(), 'fonts'] as const,
            palette: Object.assign(() => [...katanaQueryKeys.katana.meta(), 'palette'] as const, {
                colour: (colour?: string) => [...katanaQueryKeys.katana.meta.palette(), colour] as const
            })
        }),
        unsplash: Object.assign(() => [...katanaQueryKeys.katana(), 'unsplash'] as const, {
            search: (params: KatanaAPI.Katana.ServiceID.Unsplash.GET.Params) => [...katanaQueryKeys.katana.unsplash(), 'search', params] as const
        }),
        duplicatePageSection: Object.assign(() => [...katanaQueryKeys.katana(), 'duplicatePageSection'] as const, {})
    })
};
