/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
// Carousel
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Carousel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ArrowProps = {
    className: string;
    style: React.CSSProperties;
    onClick: () => void;
};

export type CarouselProps = {
    /**
     * The component you want to render for the particular slide
     */
    slides: Array<JSX.Element>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function NextArrow({ className, style, onClick }: ArrowProps) {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-right" />
        </button>
    );
}

function PrevArrow({ className, style, onClick }: ArrowProps) {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-left" />
        </button>
    );
}
// TODO: refactor the dashboard carousel and deals to use this rather than their own implementations. Make the slider props able to be passed in as props on this component
function Carousel({ slides }: CarouselProps) {
    /***** RENDER *****/
    return (
        <div className="Carousel">
            <Slider
                dots
                customPaging={(i) => <div></div>}
                arrows
                // autoplay
                autoplaySpeed={5000}
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                // responsive={[
                //     {
                //         breakpoint: 768,
                //         settings: {
                //             slidesToShow: 2,
                //             slidesToScroll: 1
                //         }
                //     },
                //     {
                //         breakpoint: 480,
                //         settings: {
                //             slidesToShow: 1,
                //             slidesToScroll: 1
                //         }
                //     }
                // ]}
            >
                {slides}
            </Slider>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Carousel;
