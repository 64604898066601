import Bold from '@tiptap/extension-bold';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { NoNewLine } from 'components/Form/RichTextEditor/plugins/NoNewLine/NoNewLinePlugin';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import { useMemo } from 'react';
import { Markdown } from 'tiptap-markdown';
import { collectionIncludes } from 'utilities/methods/collectionIncludes/collectionIncludes';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useExtensions = (features: RichTextEditorNamespace.FeatureSet[]) => {
    return useMemo(() => {
        return [
            Document,
            Paragraph,
            Text,
            collectionIncludes(features, ['floating_toolbar']) && BubbleMenu,

            History,
            collectionIncludes(features, ['markdown']) && Markdown,
            collectionIncludes(features, ['link']) &&
                Link.configure({
                    openOnClick: false,
                    autolink: true,
                    defaultProtocol: 'https'
                }),
            collectionIncludes(features, ['no_new_line']) && NoNewLine,
            collectionIncludes(features, ['heading']) && Heading,
            collectionIncludes(features, ['bold']) && Bold,
            collectionIncludes(features, ['italic']) && Italic,
            collectionIncludes(features, ['underline']) && Underline,
            collectionIncludes(features, ['strikethrough']) && Strike,
            collectionIncludes(features, ['highlight']) && Highlight,
            collectionIncludes(features, ['ul']) && BulletList,
            collectionIncludes(features, ['ol']) && OrderedList,
            collectionIncludes(features, ['ol', 'ul']) && ListItem
        ].filter((extension) => !!extension);
    }, [features]);
};
