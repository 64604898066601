import React from 'react';
import './_NXHelpBoxTitle.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _NXHelpBoxTitle: React.FC<Props> = ({ children }) => {
    return <div className="NXHelpBox__title">{children}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
