import React from 'react';
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: string;
    href: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _NXHelpBoxLink: React.FC<Props> = ({ children, href }) => {
    return (
        <Anchor className="NXHelpBox__Link" href={href}>
            {children}
        </Anchor>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
