import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { OptionsIcon } from 'containers/katana/formFields/repeated/OptionsIcon';
import { useActionHandler } from 'containers/katana/formFields/repeated/OptionsIcon/useActionHandler';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/useModifiedFieldArrayProperties';
import type { KatanaNamespace } from 'containers/katana/types';
import './__GridFieldArrayChild.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _GridFieldArrayChild: React.FC<KatanaNamespace.RepeatedPropertyFieldArrayChildProps<unknown>> = ({
    member,
    index,
    fields,
    properties,
    property
}) => {
    /***** HOOKS *****/
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const handleAction = useActionHandler({ index, fields, property, member });

    /***** RENDER HELPERS *****/
    // have to modify the properties to include the member key
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);

    /***** RENDER *****/
    return (
        <div className="KatanaRepeatedFormField__gridDisplayItem">
            <OptionsIcon onAction={handleAction} />
            <ContentEditorModuleContent>
                <PropertiesRenderer properties={modifiedProperties} />
            </ContentEditorModuleContent>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
