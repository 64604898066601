import React from 'react';
import './_NXHelpBoxDescription.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _NXHelpBoxDescription: React.FC<Props> = ({ children }) => {
    return <div className="NXHelpBox__description">{children}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
