import type { InfiniteData } from '@tanstack/react-query';
import _ from 'lodash';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/NXUtils';

/**
 * Creates an optimistic method for modifying infinite query cache data by setting the value of a specific path.
 *
 * the query data at time of setting the new value can be restored using the returned restore method, useful if an error occurs or the adding of new data needs to be reverted.
 */
export function createOptimisticInfiniteQuerySetMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(
    setQueryData: NXQueryUtils.SetInfiniteQueryDataMethod<TParams, TData>
) {
    function optimisticSet<TKey extends NXUtils.Path<TData>>(params: TParams, setPath: TKey, valueToSetPathWith: NXUtils.Choose<TData, TKey>) {
        let previousData: InfiniteData<TData> | undefined;
        setQueryData(params, (oldData) => {
            if (!oldData?.pages.length) return oldData;

            const clonedData = _.cloneDeep(oldData);

            clonedData.pages = clonedData.pages.map((page) => {
                if (page?.status !== 200) return page;
                if (!_.has(page, setPath)) return page;
                _.set(page, setPath, valueToSetPathWith);
                return page;
            });

            previousData = oldData;

            return clonedData;
        });

        return {
            restore: () => {
                if (!previousData) {
                    return;
                }
                setQueryData(params, () => previousData);
            }
        };
    }
    return optimisticSet;
}
