/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';
import { checkIsSelectHosting, checkIsVps, checkWebHostingProductStatus, isServiceRenewalBeforeDate } from '../methods';

/**
 * Checks that a service due date is before 01 May 2025
 */
function isDueBeforeMay25(serviceData) {
    return isServiceRenewalBeforeDate(serviceData, toLuxonDate('2025-05-01 00:00:00'));
}

/**
 *
 * @param {any[] | undefined} services
 * @param {(serviceData) => boolean} eligibilityCheck
 * @returns boolean
 * Performs a given check on all services and returns whether at least one service passes the check
 */
export function checkAtLeastOneEligibleService(services, eligibilityCheck) {
    return Array.isArray(services) && services.some((hosting) => eligibilityCheck(hosting));
}

/**
 * @param {any} serviceData
 * @returns boolean
 * Checks that a given hosting service is eligible for mega may hosting renewal discount
 */
export function isHostingEligibleForRenewDiscount(serviceData) {
    return checkIsSelectHosting(serviceData) && checkWebHostingProductStatus(serviceData, 'active') && isDueBeforeMay25(serviceData);
}

/**
 * @param {any} serviceData
 * @returns boolean
 * Checks that a given hosting service is eligible for cyber monday hosting renewal discount
 */
export function isHostingEligibleForCyberMondayRenewDiscount(serviceData) {
    return checkIsSelectHosting(serviceData);
}

/**
 * @param {any} serviceData
 * @returns boolean
 * Checks that a given hosting service is eligible for mega may hosting upgrade discount
 */
export function isHostingEligibleForUpgradeDiscount(serviceData) {
    return checkIsSelectHosting(serviceData) && checkWebHostingProductStatus(serviceData, 'active');
}

/**
 * @param {any} serviceData
 * @returns boolean
 * Checks that a given hosting service is eligible for mega may hosting renewal discount
 */
export function isVpsEligibleForRenewDiscount(serviceData) {
    return checkIsVps(serviceData) && checkWebHostingProductStatus(serviceData, 'active') && isDueBeforeMay25(serviceData);
}
