/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import classNames from 'classnames';
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_HelpBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type THelpBox = {
    isOpen?: boolean;
    title?: string;
    desc?: string;
    bullets?: string[];
    position?: 'left' | 'right';
    link?: string[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The HelpBox component provides a standardized box that should be used to provide help text to the user.
 * This is in the format of a title, description and a list of bullet points, similar to the NXBox but with bullet points.
 */

/**
 * @deprecated Use NXHelpBox instead. 'NXHelpBox' is a compound version of 'HelpBox'. 'NXHelpBox' is much more flexible and scalable. And you can even choose position/direction of the help box with 'NXHelpBox'!
 */

const HelpBox: React.FC<THelpBox> = ({ isOpen, title, desc, bullets, position, link }) => {
    /***** RENDER *****/
    return (
        <div className={classNames('HelpBox', { 'HelpBox--open': isOpen, [`HelpBox--position-${position}`]: position })}>
            <div className="HelpBox__triangle">
                <div className="HelpBox__triangleInner"></div>
            </div>
            {title ? <div className="HelpBox__title">{title}</div> : ''}
            {desc ? <div className="HelpBox__desc">{desc}</div> : ''}
            {bullets ? (
                <ul className="HelpBox__bullets">
                    {bullets.map((bullet, index) => (
                        <li key={index}>{bullet}</li>
                    ))}
                </ul>
            ) : (
                ''
            )}
            {link ? <Anchor href={link[1]}>{link[0]}</Anchor> : ''}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default HelpBox;
