import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { MINUTE } from 'utilities/consts';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.socials.GET>>;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.socials(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.socials.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function invalidateQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSocials = Object.freeze({
    useQuery: _useQuery,
    invalidateQueries,
    prefetchQuery,
    createQueryKey,
    optimistic: createOptimisticMethods(setQueryData),
    createQueryOptions
});
