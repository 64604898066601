import { useMutation } from '@tanstack/react-query';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceID, options = {}) {
    async function onMutate(sectionID: KatanaNamespace.SectionID) {
        /**
         * Optimistically remove the section from the view.
         * first cancel existing queries, then set the data to the new data.
         */
        const cancelQueries = [sections.cancelQueries(serviceID), service.cancelQueries(serviceID)];

        await Promise.allSettled(cancelQueries);

        section.removeQueries({ serviceID, sectionID });

        return sections.optimistic.filter(serviceID, 'data', (section) => section.id !== sectionID);
    }

    async function mutationFn(sectionID: KatanaNamespace.SectionID) {
        return KATANA_API.katana.site.service_id.section.section_id.DELETE({ serviceID, sectionID });
    }

    return useMutation({
        mutationFn,
        onMutate,
        onError: (e, v, context) => {
            context?.restore();
            handleDefaultErrorNotification(e);
        },
        onSettled: (data, error, variables) => {
            sections.invalidateQueries(serviceID);
            service.invalidateQueries(serviceID);
        },
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const deleteSection = Object.freeze({
    useMutation: _useMutation
});
