import classNames from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import type { OptionsIconNamespace } from 'containers/katana/formFields/repeated/OptionsIcon/types';
import React, { useRef } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useClickAway } from 'utilities/hooks/useClickAway';
import './_OptionsIcon.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const OptionsIcon: React.FC<OptionsIconNamespace.Props> = ({ onAction }) => {
    /***** STATE *****/
    const { value: isMenuOpen, setFalse: hideMenu, toggle: toggleMenu } = useBoolean(false);

    /***** HOOKS *****/
    const wrapperRef = useRef(null);

    useClickAway([wrapperRef], hideMenu);

    /***** FUNCTIONS *****/
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        toggleMenu();
    };

    function handleAction(action: OptionsIconNamespace.Action) {
        onAction(action);
        hideMenu();
    }

    /***** RENDER *****/
    return (
        <div className="OptionsIcon__wrapper HoverBlocker" ref={wrapperRef}>
            <button type="button" className={classNames('OptionsIcon', { 'OptionsIcon--isMenuOpen': isMenuOpen })} onClick={handleClick}>
                <PhosphorIcons.DotsThree.Bold secondary size={24} />
            </button>

            {isMenuOpen && (
                <Flex gap={0} direction="column" className="OptionsIcon__buttonsWrapper">
                    <OutlineButton
                        color="secondary-alt"
                        type="onClick"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAction('duplicate');
                        }}
                    >
                        <Text lead--l align--right size--xs medium>
                            Duplicate
                        </Text>
                    </OutlineButton>
                    <OutlineButton
                        color="secondary-alt"
                        type="onClick"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAction('delete');
                        }}
                    >
                        <Text lead--l align--right size--xs medium>
                            Delete
                        </Text>
                    </OutlineButton>
                </Flex>
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { OptionsIcon };
