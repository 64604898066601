import type { Level } from '@tiptap/extension-heading';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextFeatures } from 'components/Form/RichTextEditor/hooks/useRichTextFeatures';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import { actionMap } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/consts';
import { FormatHeadingLevelAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatHeadingLevelAction';
import { OrderedListAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/OrderedListAction';
import type { ActionKey } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/types';
import { UnorderedListAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/UnorderedListAction';
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';
import { ToolbarDropdown } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarDropdown/ToolbarDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { useMemo, type MouseEventHandler } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    levels?: Level[];
    isCondensed?: boolean;
};

type HeadingActiveDictionary = {
    isHeading1Active?: boolean;
    isHeading2Active?: boolean;
    isHeading3Active?: boolean;
    isHeading4Active?: boolean;
    isHeading5Active?: boolean;
    isHeading6Active?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormatTextTypeAction: React.FC<Props> = ({ levels = [1, 2, 3, 4, 5, 6], isCondensed }) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const iconSize = useRichTextIconSize();
    const { value: isDropdownOpen, setFalse: closeDropdown, setValue: setIsDropdownOpen } = useBoolean(false);
    const hasHeading = useRichTextFeatures('heading');
    const hasUnorderedList = useRichTextFeatures('ul');
    const hasOrderedList = useRichTextFeatures('ol');
    /***** FUNCTIONS *****/
    const handleToggleParagraph: MouseEventHandler<HTMLButtonElement> = (e) => {
        closeDropdown();
        editor?.chain().focus().clearNodes().run();
    };

    /***** RENDER HELPERS *****/
    const headingActiveRecord = levels.reduce<HeadingActiveDictionary>((acc, level) => {
        const isActive = editor?.isActive('heading', { level });
        acc[`isHeading${level}Active`] = isActive;

        return acc;
    }, {});

    const isUnorderedListActive = editor?.isActive('bulletList');
    const isOrderedListActive = editor?.isActive('orderedList');
    const isParagraphActive = editor?.isActive('paragraph');

    const activeAction: ActionKey = useMemo(() => {
        if (headingActiveRecord.isHeading1Active) return 'heading1';
        if (headingActiveRecord.isHeading2Active) return 'heading2';
        if (headingActiveRecord.isHeading3Active) return 'heading3';
        if (headingActiveRecord.isHeading4Active) return 'heading4';
        if (headingActiveRecord.isHeading5Active) return 'heading5';
        if (headingActiveRecord.isHeading6Active) return 'heading6';
        if (isCondensed) {
            if (isUnorderedListActive) return 'ul';
            if (isOrderedListActive) return 'ol';
        }
        return 'paragraph';
    }, [headingActiveRecord, isUnorderedListActive, isOrderedListActive]);

    const buttonText = useMemo(() => {
        if (isCondensed) return '';
        if (activeAction === 'paragraph') return 'Text Type';
        return actionMap[activeAction].name;
    }, [activeAction, isCondensed]);

    if (!hasHeading && !isCondensed) return null;

    /***** RENDER *****/
    return (
        <ToolbarDropdown
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
            Icon={actionMap[activeAction].icon}
            ariaLabel="Format Text Type"
            buttonText={buttonText}
        >
            <ToolbarButton
                isToolTipDisabled
                width="auto"
                ariaLabel="Format Paragraph"
                onClick={handleToggleParagraph}
                isActive={isParagraphActive}
                text="Paragraph"
            >
                <PhosphorIcons.Paragraph.Bold size={iconSize} black={isParagraphActive} />{' '}
            </ToolbarButton>

            {levels.map((level) => (
                <FormatHeadingLevelAction key={level} level={level} onClick={closeDropdown} />
            ))}
            {isCondensed && (
                <>
                    <UnorderedListAction
                        onAfterClick={closeDropdown}
                        intrinsicProps={{ text: actionMap.ul.name, isToolTipDisabled: true, width: 'full' }}
                    />
                    <OrderedListAction
                        onAfterClick={closeDropdown}
                        intrinsicProps={{ text: actionMap.ol.name, isToolTipDisabled: true, width: 'full' }}
                    />
                </>
            )}
        </ToolbarDropdown>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
