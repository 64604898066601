import { useComponentDebugTrackerCount, useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    __debugEnabled?: boolean;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useIsDebuggingRichText = ({ __debugEnabled }: Props) => {
    /***** HOOKS *****/
    useComponentDebugTrackerCount('RichTextEditor');
    const { value: isRichTextEditorDebugEnabled } = useSuperUserActionKey('RICH_TEXT_EDITOR_DEBUG');

    /***** HOOK RESULTS *****/
    return useMemo(() => __debugEnabled || isRichTextEditorDebugEnabled, [__debugEnabled, isRichTextEditorDebugEnabled]);
};
