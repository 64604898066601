import type { TooltipProps } from '@mui/material';
import { Tooltip } from '@mui/material';
import React from 'react';
import './_NXTooltip.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXTooltipProps = React.FC<TooltipProps>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const defaultValues: Partial<TooltipProps> = {
    placement: 'top',
    arrow: true,
    enterTouchDelay: 50, //Lower values than 50 have a tendancy to not appear properly
    leaveTouchDelay: 3000 //This ensures it always appears for 3 seconds
} as const;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wrapper around MUI's Tooltip that creates custom styling for an on-hover tooltip.
 *
 * Takes text or element *without* an onclick event for good UX.
 */
export const NXTooltip: NXTooltipProps = ({ children, ...intrinsicProps }) => {
    /***** CONSTS *****/
    const mergedProps = { ...defaultValues, ...intrinsicProps };

    /***** RENDER *****/
    return (
        <Tooltip {...mergedProps} slotProps={{ tooltip: { className: 'NXTooltip' } }}>
            <span>{children}</span>
        </Tooltip>
    );
};
