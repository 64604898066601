import { BubbleMenu } from '@tiptap/react';
import classNames from 'classnames';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';
import { useRichTextFeatures } from 'components/Form/RichTextEditor/hooks/useRichTextFeatures';
import { FormatBoldAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatBoldAction';
import { FormatHighlightAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatHighlightAction';
import { FormatItalicAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatItalicAction';
import { FormatStrikethroughAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatStrikethroughAction';
import { FormatTextTypeAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatTextTypeAction';
import { FormatUnderlineAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatUnderlineAction';
import { InsertLinkAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/InsertLinkAction';
import { OrderedListAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/OrderedListAction';
import { UnorderedListAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/UnorderedListAction';
import { textStylingDropdownClass } from 'components/Form/RichTextEditor/plugins/Toolbar/consts';
import { ToolbarDropdown } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarDropdown/ToolbarDropdown';
import { useCondensedBreakpoints } from 'components/Form/RichTextEditor/plugins/Toolbar/useCondensedBreakpoints';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import { WrapWithComponent } from 'components/WrapWithComponent';
import React, { useState } from 'react';
import './_ToolBarPlugin.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Reference for BubbleMenu plugin
 * @link https://tiptap.dev/docs/editor/extensions/functionality/bubble-menu#page-title
 */
export const ToolbarPlugin = () => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const hasFloatingMenu = useRichTextFeatures('floating_toolbar');
    const [canShowFloatingToolbar] = useRichTextEditorContext('canShowFloatingToolbar');
    const toolBarRef = React.useRef<HTMLDivElement>(null);

    const [isTextStylingCondensedOpen, setIsTextStylingCondensedOpen] = useState(false);
    const { isTextStylingCondensed, isHeadingFormatCondensed } = useCondensedBreakpoints(toolBarRef);

    // const hasUndo = useRichTextFeatures('undo');
    // const hasRedo = useRichTextFeatures('redo');
    const hasBold = useRichTextFeatures('bold');
    const hasItalic = useRichTextFeatures('italic');
    const hasStrikethrough = useRichTextFeatures('strikethrough');
    const hasUnderline = useRichTextFeatures('underline');
    const hasUnorderedList = useRichTextFeatures('ul');
    const hasOrderedList = useRichTextFeatures('ol');
    const hasLink = useRichTextFeatures('link');
    const hasHighlight = useRichTextFeatures('highlight');
    const hasHeading = useRichTextFeatures('heading');
    // const hasAlignment = useRichTextFeatures('alignment');
    // const hasClear = useRichTextFeatures('clear');

    const hasActionFeatures =
        [
            // hasUndo,
            // hasRedo,
            hasBold,
            hasItalic,
            hasStrikethrough,
            hasUnderline,
            hasUnorderedList,
            hasOrderedList,
            hasLink,
            hasHighlight,
            hasHeading
            // hasAlignment,
            // hasClear,
        ].filter(Boolean).length > 0;

    /***** RENDER *****/
    if (!hasActionFeatures) return null;
    if (!editor) return null;
    return (
        <WrapWithComponent
            component={BubbleMenu}
            wrap={hasFloatingMenu}
            editor={editor}
            shouldShow={() => {
                return canShowFloatingToolbar;
            }}
        >
            <Border all inject>
                <Flex className="RichTextEditor__toolBar" gap={0} elementRef={toolBarRef}>
                    {/* {collectionIncludes(features, ['undo']) && <UndoAction />} */}
                    {/* {collectionIncludes(features, ['redo']) && <RedoAction />} */}
                    {(hasHeading || hasUnorderedList || hasOrderedList) && <FormatTextTypeAction isCondensed={isHeadingFormatCondensed} />}
                    <ToolbarDropdown
                        className={classNames(textStylingDropdownClass, {
                            [`${textStylingDropdownClass}--condensed`]: isTextStylingCondensed
                        })}
                        isOpen={isTextStylingCondensedOpen}
                        setIsOpen={setIsTextStylingCondensedOpen}
                        Icon={PhosphorIcons.TextAa.Bold}
                        ariaLabel="Text Styling Menu"
                        orientation="horizontal"
                    >
                        {hasBold && <FormatBoldAction />}
                        {hasItalic && <FormatItalicAction />}
                        {hasUnderline && <FormatUnderlineAction />}
                        {hasStrikethrough && <FormatStrikethroughAction />}
                    </ToolbarDropdown>
                    {!isTextStylingCondensed && (
                        <>
                            {hasBold && <FormatBoldAction />}
                            {hasItalic && <FormatItalicAction />}
                            {hasUnderline && <FormatUnderlineAction />}
                            {hasStrikethrough && <FormatStrikethroughAction />}
                        </>
                    )}
                    {!isHeadingFormatCondensed && (
                        <>
                            {hasUnorderedList && <UnorderedListAction />}
                            {hasOrderedList && <OrderedListAction />}
                        </>
                    )}
                    {hasLink && <InsertLinkAction />}
                    {hasHighlight && <FormatHighlightAction />}
                    {/* {collectionIncludes(features, ['clear']) && <ClearEditorAction />} */}
                </Flex>
            </Border>
        </WrapWithComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
