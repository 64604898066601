import { _RichTextEditor } from 'components/Form/RichTextEditor/_RichTextEditor';
import { _RichTextEditorInput } from 'components/Form/RichTextEditor/_RichTextEditorInput';
import { _RichTextEditorInputReduxForm } from 'components/Form/RichTextEditor/_RichTextEditorInput/wrappers/reduxForm/Default';
import { _RichTextEditorTextArea } from 'components/Form/RichTextEditor/_RichTextEditorTextArea';
import { _RichTextEditorTextAreaReduxForm } from 'components/Form/RichTextEditor/_RichTextEditorTextArea/wrappers/reduxform/Default';

/**
 * Using Tip Tap under the hood: https://tiptap.dev/docs/editor/extensions/overview
 */
export const RichTextEditor = Object.assign(_RichTextEditor, {
    TextArea: Object.assign(_RichTextEditorTextArea, {
        ReduxForm: _RichTextEditorTextAreaReduxForm
    }),
    Input: Object.assign(_RichTextEditorInput, {
        ReduxForm: _RichTextEditorInputReduxForm
    })
});
