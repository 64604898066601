import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';

export function getServiceInfoDataFromPath(id: KatanaNamespace.ServiceID, path: string) {
    const serviceInfo = katanaQuery.serviceID.service.getQueryData(id);
    if (!serviceInfo) {
        return null;
    }
    if (!('data' in serviceInfo)) {
        return;
    }
    return _.get(serviceInfo.data, path);
}
