import _ from 'lodash';
import { createOptimisticSetMethod } from 'utilities/methods/tanstack/optimistic/createOptimisticSetMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/NXUtils';

// prettier-ignore
/**
 * Creates an optimistic array concat method for modifying query cache data by concatenating arrays at a specified path.
 *
 * original array can be restored using the returned restore method, useful if an error occurs or the filtered data needs to be reverted.
 */
export function createOptimisticConcatMethod<
	TParams extends any,
	TData extends NXQueryUtils.ApiData
>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>
) {
    const optimisticSet = createOptimisticSetMethod(setQueryData);
    function optimisticConcat<
        TKey extends NXUtils.Path<TData>,
        TConcatValue extends NXUtils.Choose<TData, TKey> extends Array<any> ? NXUtils.Choose<TData, TKey>[number] : never
    >(params: TParams, concatPath: TKey, concatValue: TConcatValue[]) {
        let previousValue: TConcatValue[] | undefined;

        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            if (!_.has(oldData, concatPath)) {
                return;
            }

            const clonedData = _.cloneDeep(oldData);

            const value = _.get(clonedData, concatPath);
            if (!_.isArray(value)) {
                return;
            }

            previousValue = _.cloneDeep(value);

            const filteredValue = _.concat(value, concatValue);

            _.set(clonedData, concatPath, filteredValue);

            return clonedData;
        });

        // Restore the removed entries
        return {
            restore: () => {
                if (!previousValue) {
                    return;
                }

                optimisticSet(params, concatPath, previousValue as NXUtils.Choose<TData, TKey>);
            }
        };
    }

    return optimisticConcat;
}
