import BackgroundImage from 'components/BackgroundImage';
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { NXSquare } from 'components/Utils/NXSquare';
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useEffect, useMemo } from 'react';
import { Field } from 'redux-form';
import './_SectionStyleData.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SectionStyleData({ styles = {}, label = 'Content Style', name = 'style' }) {
    /***** RENDER HELPERS *****/
    const styleEntries = useMemo(() => Object.values(styles), [styles]);
    const hasMultipleStyles = styleEntries?.length > 1;

    /***** HOOKS *****/
    const { dynamicChange } = useDynamicFormActions();

    /***** EFFECTS *****/
    useEffect(() => {
        if (!hasMultipleStyles) {
            const defaultStyleValue = styleEntries[0]?.key;
            dynamicChange('style', defaultStyleValue);
        }
    }, [styleEntries, hasMultipleStyles]);

    /***** RENDER HELPERS *****/
    const options = useMemo(
        () =>
            styleEntries.map((entry) => {
                const { key, title, icon } = entry;

                const newEntry: CardSelectGridNamespace.SelectCard.CustomOption = {
                    type: 'custom',
                    content: (
                        <BackgroundColour light-gray>
                            <NXSquare ratio={0.58}>
                                <BackgroundImage imageURL={icon} contain fill />
                            </NXSquare>
                        </BackgroundColour>
                    ),
                    selectionText: title,
                    value: key
                };
                return newEntry;
            }),
        [styles, styleEntries]
    );

    /***** RENDER *****/
    if (!hasMultipleStyles) {
        return null;
    }
    return (
        <Field
            name={name}
            label={label}
            className="SectionStyleData"
            options={options}
            component={CardSelectGrid.ReduxForm}
            alignItems="stretch"
            textAlign="left"
        />
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SectionStyleData;
