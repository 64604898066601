/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import CenteredNavImg from 'assets/images/katana/centered-navigation.svg';
import SidebarNavImg from 'assets/images/katana/side-navigation.svg';
import SimpleNavImg from 'assets/images/katana/simple-navigaiton.svg';
import StandardNavImg from 'assets/images/katana/standard-navigation.svg';
import BackgroundImage from 'components/BackgroundImage';
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { NXSquare } from 'components/Utils/NXSquare';
import Padding from 'components/Utils/Padding';
import { getColourStyleSelectContent } from 'containers/katana/containers/ContentEditorLightbox/methods/getColourStyleSelectContent';
import { useFormContext } from 'react-hook-form';
const { COLOR, TRANSPARENT, VISIBLE, HEADER_STYLE } = usePostNavigationMutation.FORM_FIELD_KEYS;
const navigationLayoutOptions = [
    {
        key: 'standard',
        title: 'Standard Navigation',
        icon: StandardNavImg
    },
    {
        key: 'centered',
        title: 'Centered Logo Navigation',
        icon: CenteredNavImg
    },
    {
        key: 'simple',
        title: 'Simple Navigation',
        icon: SimpleNavImg
    },
    {
        key: 'sidebar',
        title: 'Side Navigation',
        icon: SidebarNavImg
    }
];

const styleValues = Object.values(navigationLayoutOptions);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavigationColourThemeContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { watch, setValue } = useFormContext();

    const isSideBar = watch(HEADER_STYLE) === 'sidebar';

    /***** QUERIES *****/
    const { data: get_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId);
    const { data: get_navigation_info_data, isLoading: isGetKatanaNavigationInfoLoading } =
        katanaQuery.serviceID.getNavigation.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const constructedColourOptions = useMemo(
        () => [
            { type: 'custom', value: 'white', selectionText: 'Light Contrast', content: getColourStyleSelectContent('light') },
            {
                type: 'custom',
                value: get_site_info_data?.color,
                selectionText: 'Colour Contrast',
                content: getColourStyleSelectContent('standard')
            },
            { type: 'custom', value: 'black', selectionText: 'Dark Contrast', content: getColourStyleSelectContent('dark') }
        ],
        [get_site_info_data]
    );
    const colourOptions = isGetKatanaSiteInfoLoading ? [] : constructedColourOptions;

    // Options for the Navigation layoutStyles
    const constructedNavLayoutOptions = useMemo(
        () =>
            styleValues.map((value) => {
                const { key, title, icon } = value;

                const newEntry: CardSelectGridNamespace.SelectCard.CustomOption = {
                    type: 'custom',
                    content: (
                        <BackgroundColour light-gray>
                            <NXSquare ratio={0.58}>
                                <BackgroundImage imageURL={icon} contain fill />
                            </NXSquare>
                        </BackgroundColour>
                    ),
                    selectionText: title,
                    value: key
                };
                return newEntry;
            }),
        []
    );

    const navStyleOptions = isGetKatanaNavigationInfoLoading ? [] : constructedNavLayoutOptions;

    /***** EFFECTS *****/
    useEffect(() => {
        if (isSideBar) {
            setValue(TRANSPARENT, false);
        }
    }, [isSideBar]);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Appearance"
            description="Choose how you want to display the content and select the colour scheme for this section. You can preview the changes made to your site in real time!"
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    {isGetKatanaSiteInfoLoading ? (
                        <RequestLoader message="Loading Navigation Styles..." />
                    ) : (
                        <>
                            <Padding bottom={10}>
                                <CardSelectGrid.HookForm
                                    key={get_navigation_info_data?.style?.header_style}
                                    name={HEADER_STYLE}
                                    itemsPerRow={2}
                                    options={navStyleOptions}
                                    label="Layout style"
                                />
                            </Padding>
                            <CardSelectGrid.HookForm key={get_site_info_data?.color} name={COLOR} itemsPerRow={3} options={colourOptions} />
                            <CheckBoxList.Item.HookForm name={TRANSPARENT} disabled={isSideBar}>
                                <Text semiBold black size--m>
                                    Make my navigation background transparent
                                </Text>
                            </CheckBoxList.Item.HookForm>
                            <Text secondary size--s italic>
                                This feature is currently not compatible with all section and style configurations. It is recommended to be used with
                                either the Hero Banner or About Us sections.
                            </Text>
                            <CheckBoxList.Item.HookForm name={VISIBLE} inverse>
                                <Text semiBold black size--m>
                                    Hide my navigation
                                </Text>
                            </CheckBoxList.Item.HookForm>
                            <Text secondary size--s italic>
                                Enabling this setting will prevent both your navigation and footer from displaying. This can be useful when creating a
                                very simplistic website.
                            </Text>
                        </>
                    )}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
