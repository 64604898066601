/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isPointerEventWithinRefList } from 'utilities/methods/isPointerEventWithinInRefList';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Adds `pointerdown`, `pointerup`, `pointerdown`, `pointerup` event listeners to the document to call the onClose function if the event target is an element outside of the dropdown. Removes the event listener on unmount.
 * Very Similar to `useClickAway`, except this hook will only call the onClose function if the pointerdown and pointerup events happened outside of the refs.
 * This prevents the issue where a user "drags" from within the ref, and ends outside the refs, causing it to close when it needs to remain open.
 */
export function useClickAwayOutsideRefOnly(
    refs: Array<React.MutableRefObject<Element | null> | Element | null>,
    onClose: (e: PointerEvent | FocusEvent) => void
) {
    /***** HOOKS *****/
    const isMouseStartedOutsideRef = useRef(false);

    /***** FUNCTIONS *****/
    const handlePointerDown = useCallback(
        (e: PointerEvent) => {
            isMouseStartedOutsideRef.current = !isPointerEventWithinRefList(e, refs);
        },
        [refs]
    );

    const handlePointerUp = useCallback(
        (e: PointerEvent) => {
            const isPointerOutsideRefList = !isPointerEventWithinRefList(e, refs);
            if (isPointerOutsideRefList && isMouseStartedOutsideRef.current) {
                onClose(e);
            }
            isMouseStartedOutsideRef.current = false;
        },
        [onClose, refs]
    );

    /***** EFFECTS *****/
    useEventListener('blur', onClose);
    useEffect(() => {
        document.addEventListener('pointerdown', handlePointerDown);
        document.addEventListener('pointerup', handlePointerUp);

        return () => {
            document.removeEventListener('pointerdown', handlePointerDown);
            document.removeEventListener('pointerup', handlePointerUp);
        };
    }, [onClose, refs, handlePointerUp, handlePointerDown]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
