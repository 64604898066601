import type { Level } from '@tiptap/extension-heading';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextIconSize } from 'components/Form/RichTextEditor/hooks/useRichTextIconSize';
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { MouseEventHandler } from 'react';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    level: Level;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormatHeadingLevelAction: React.FC<Props> = ({ level, onClick }) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const iconSize = useRichTextIconSize();

    /***** FUNCTIONS *****/
    const handleToggleHeading: MouseEventHandler<HTMLButtonElement> = (e) => {
        onClick?.(e);
        editor?.chain().focus().toggleHeading({ level }).run();
    };

    /***** RENDER HELPERS *****/
    const isActive = editor?.isActive('heading', { level });

    /***** RENDER *****/
    return (
        <ToolbarButton
            isToolTipDisabled
            width="full"
            ariaLabel={`Format Heading ${level}`}
            onClick={handleToggleHeading}
            isActive={isActive}
            text={`Heading ${level}`}
        >
            <PhosphorIcons.TextHeading1.Bold size={iconSize} black={isActive} />
        </ToolbarButton>
    );
};
