/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import axios from 'axios';
import { nxAxios, NXAxiosInterceptor } from 'store/nxAxios';
import { ARTAHInterceptor } from 'utilities/methods/ArtahInterceptor';

export function interceptorHandler() {
    // Artah needs to run first because it needs the clean request
    if (import.meta.env.MODE === 'development' && import.meta.env.VITE_ARTAH_ENABLED) {
        ARTAHInterceptor(nxAxios);
        ARTAHInterceptor(axios);
    }
    // this interceptor messes with the response
    NXAxiosInterceptor();
}
