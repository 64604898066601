import * as Sentry from '@sentry/react';
import {
    isRichTextData,
    isTipTapDocumentJSON
} from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import type { MinMaxValidationValue } from 'utilities/methods/validators';
import { validateMinimum } from 'utilities/methods/validators';

export function getRepeatedValidationMinTypeMessage(value: MinMaxValidationValue, minValue: number) {
    if (typeof value === 'string') {
        return `Must be at least ${minValue} characters long.`;
    }

    if (typeof value === 'number') {
        return `Minimum is ${minValue}.`;
    }

    if (Array.isArray(value)) {
        return `Must have at least ${minValue} entr${minValue === 1 ? 'y' : 'ies'}.`;
    }

    if (value === null || value === undefined) {
        return undefined;
    }

    if (isRichTextData(value) || isTipTapDocumentJSON(value)) {
        return `A minimum of ${minValue} Rich text characters is required`;
    }

    const message = `value type not supported for min type validation, value: ${JSON.stringify(value)}, ${value?.constructor?.name}, ${typeof value}, minValue: ${minValue}`;
    console.error(message);
    Sentry.captureMessage(message);

    return undefined;
}

export function validationMinimum(value: MinMaxValidationValue, minValue: number) {
    const textResult = getRepeatedValidationMinTypeMessage(value, minValue);
    const result = validateMinimum(value, minValue);
    return result ? undefined : textResult;
}
