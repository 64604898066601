/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { keys } from 'lodash';
import React from 'react';
import { isElement } from 'react-is';

/**********************************************************************************************************
 *  All things react children related
 **********************************************************************************************************/
/**
 * @param {import('react').ReactElement} children
 * @param {InjectableChildPropKeys} key
 * @param {unknown} value
 */
function handlePropKeyInjectionValue(children, key, value) {
    switch (key) {
        case 'className':
            return classNames(children?.props?.className, value);
        default:
            return value;
    }
}

/**
 * @param {import('react').ReactElement} children
 * @param {InjectableChildrenPropRecord} record
 */
function constructInjectableChildPropsByRecord(children, record) {
    const finalPropObject = {};

    // for each key in the record, attempt to pass the props to the children, if the prop does not exist on the child don't add the key to the prop object
    keys(record).forEach((/** @type {InjectableChildPropKeys} */ key) => {
        // We don't know if the child has the prop, so we're kind of just trying to inject it regardless
        finalPropObject[key] = handlePropKeyInjectionValue(children, key, record[key]);
    });

    return finalPropObject;
}

/**
 * @param {import('react').ReactNode} children
 * @param {InjectableChildrenPropRecord} record
 */
export function injectChildPropsByRecord(children, record) {
    if (!isElement(children)) {
        const isArray = Array.isArray(children);
        console.error(
            `The ReactNode passed to injectChildPropsByKey was not a ReactElement${isArray && ' but an array of elements'}, please ensure you are passing a ${isArray && 'single '}ReactElement`
        );
        return children;
    }

    // TODO: Add message for props that didn't get injected
    const finalPropObject = constructInjectableChildPropsByRecord(children, record);

    if (!keys(finalPropObject).length) {
        return children;
    }

    return React.cloneElement(children, finalPropObject);
}
