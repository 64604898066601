/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   Katana Update Social Links
 **********************************************************************************************************/
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useUpdateSocialLinksMutation(serviceID: number, options = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();

    /***** RENDER *****/
    return useMutation({
        mutationFn: (attributes: KatanaAPI.Katana.ServiceID.Socials.POST.Params['attributes']) =>
            KATANA_API.katana.service_id.socials.POST({ serviceID, attributes }),
        onError: handleDefaultErrorNotification,
        onSuccess: page === 'setup' ? undefined : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.getSocials.invalidateQueries(serviceID);
            katanaQuery.serviceID.service.invalidateQueries(serviceID);
        },
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
useUpdateSocialLinksMutation.FORM_FIELD_KEYS = {
    SOCIALS: 'socials'
} as const;

export { useUpdateSocialLinksMutation };
