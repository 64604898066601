/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';
import RequestLoader from 'components/Loaders/Request';
import { ScalingIframe } from 'components/NXIframe/ScalingIframe';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SitePreviewContent.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SitePreviewContentProps = {
    serviceID?: number;
    ratio?: number;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const SitePreviewContent: React.FC<SitePreviewContentProps> = ({ serviceID, ratio = 0.75 }) => {
    /***** HOOKS *****/
    const { katanaServiceId = serviceID } = useKatanaParams();
    const ID = serviceID ?? katanaServiceId;

    /***** QUERIES *****/
    const { data: get_katana_theme_preset_data, isLoading: isGetKatanaThemePresetsLoading } = katanaQuery.serviceID.meta.getThemePresets.useQuery(ID);
    const { data: get_katana_service_data } = katanaQuery.serviceID.service.useQuery(ID);

    const {
        data: get_site_preview_data,
        isLoading: isGetSitePreviewLoading,
        isFetching: isGetSitePreviewFetching,
        isError: isGetSitePreviewError
    } = katanaQuery.serviceID.getSitePreview.useQuery({ serviceID: ID });

    /***** RENDER *****/
    return (
        <NXSquare ratio={ratio} className="SitePreviewContent">
            {(function () {
                if (isGetSitePreviewLoading) {
                    return <RequestLoader message="Getting Site Preview..." />;
                }
                if (isGetSitePreviewError) {
                    if (isGetKatanaThemePresetsLoading) {
                        return <RequestLoader message="Loading Theme Template Data..." />;
                    }

                    return (
                        <BackgroundImage
                            imageURL={
                                get_katana_theme_preset_data?.[get_katana_service_data?.attributes?.preset_meta?.preset_id || 'katana.v1.custom']
                                    ?.preview
                            }
                            cover
                            fill
                            center-top
                        />
                    );
                }

                // If there's no preset selected yet, show the "custom" preview image
                if (!get_katana_service_data?.attributes?.preset_meta?.preset_id && !get_katana_service_data?.attributes?.preset_meta?.custom) {
                    return <BackgroundImage imageURL={get_katana_theme_preset_data?.['katana.v1.custom']?.preview} cover fill center-top />;
                }

                return (
                    <IsDataUpdatingOverlay.StableDomStructure
                        isDataUpdating={isGetSitePreviewFetching}
                        message="Fetching latest preview..."
                        Variant={RequestLoader.WithBackground}
                    >
                        <ScalingIframe
                            tabIndex={-1}
                            scrolling="no"
                            width={1280}
                            height={1280}
                            srcDoc={get_site_preview_data ?? ''}
                            title="Site Preview"
                        />
                    </IsDataUpdatingOverlay.StableDomStructure>
                );
            })()}
        </NXSquare>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SitePreviewContent;
