import { pushNotification } from 'components/Toast/functions';
import { API } from 'utilities/api/hosting';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

export const HOSTING_SECURITY_INITIAL_STATE = 'hosting/HOSTING_SECURITY_INITIAL_STATE';
export const HOSTING_SECURITY_REQUEST_RESET = 'hosting/HOSTING_SECURITY_REQUEST_RESET';

export const HOSTING_AUTOSSL_CHECK_REQUEST = 'hosting/HOSTING_AUTOSSL_CHECK_REQUEST';
export const HOSTING_AUTOSSL_CHECK_SUCCESS = 'hosting/HOSTING_AUTOSSL_CHECK_SUCCESS';
export const HOSTING_AUTOSSL_CHECK_FAIL = 'hosting/HOSTING_AUTOSSL_CHECK_FAIL';

export const HOSTING_PERMISSIONS_REQUEST = 'hosting/HOSTING_PERMISSIONS_REQUEST';
export const HOSTING_PERMISSIONS_SUCCESS = 'hosting/HOSTING_PERMISSIONS_SUCCESS';
export const HOSTING_PERMISSIONS_FAIL = 'hosting/HOSTING_PERMISSIONS_FAIL';

export const HOSTING_MODSEC_REQUEST = 'hosting/HOSTING_MODSEC_REQUEST';
export const HOSTING_MODSEC_SUCCESS = 'hosting/HOSTING_MODSEC_SUCCESS';
export const HOSTING_MODSEC_FAIL = 'hosting/HOSTING_MODSEC_FAIL';

export const HOSTING_FILE_QUARANTINE_REQUEST = 'hosting/HOSTING_FILE_QUARANTINE_REQUEST';
export const HOSTING_FILE_QUARANTINE_SUCCESS = 'hosting/HOSTING_FILE_QUARANTINE_SUCCESS';
export const HOSTING_FILE_QUARANTINE_FAIL = 'hosting/HOSTING_FILE_QUARANTINE_FAIL';

/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/

export const resetHostingSecurity = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SECURITY_INITIAL_STATE
        });
    };
};

export const resetHostingSecurityRequests = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SECURITY_REQUEST_RESET
        });
    };
};

/**********************************************************************************************************
 *   AUTOSSL
 **********************************************************************************************************/

export const getAutoSslStatus = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_AUTOSSL_CHECK_REQUEST
            });
            API.hosting.subaccount.GET.autossl(id, username)
                .then((response) => {
                    const hosting_autossl_check_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_AUTOSSL_CHECK_SUCCESS,
                        hosting_autossl_check_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_AUTOSSL_CHECK_FAIL,
                        hosting_autossl_check_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_AUTOSSL_CHECK_REQUEST
        });
        API.hosting.GET.autossl(id)
            .then((response) => {
                const hosting_autossl_check_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_AUTOSSL_CHECK_SUCCESS,
                    hosting_autossl_check_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_AUTOSSL_CHECK_FAIL,
                    hosting_autossl_check_error: response
                });
            });
    };
};

export const startAutoSslStatus = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_AUTOSSL_CHECK_REQUEST
            });
            API.hosting.subaccount.POST.autossl(id, username)
                .then((response) => {
                    const hosting_autossl_check_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_AUTOSSL_CHECK_SUCCESS,
                        hosting_autossl_check_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_AUTOSSL_CHECK_FAIL,
                        hosting_autossl_check_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_AUTOSSL_CHECK_REQUEST
        });
        API.hosting.POST.autossl(id)
            .then((response) => {
                const hosting_autossl_check_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_AUTOSSL_CHECK_SUCCESS,
                    hosting_autossl_check_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_AUTOSSL_CHECK_FAIL,
                    hosting_autossl_check_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   MODSEC
 **********************************************************************************************************/

export const getModSec = (id, subaccount) => {
    if (subaccount) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_MODSEC_REQUEST
            });
            API.hosting.subaccount.GET.modSec(id, subaccount)
                .then((response) => {
                    const hosting_modsec_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_MODSEC_SUCCESS,
                        hosting_modsec_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_MODSEC_FAIL,
                        hosting_modsec_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_MODSEC_REQUEST
        });
        API.hosting.GET.modSec(id)
            .then((response) => {
                const hosting_modsec_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_MODSEC_SUCCESS,
                    hosting_modsec_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_MODSEC_FAIL,
                    hosting_modsec_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   FILE QUARANTINE
 **********************************************************************************************************/

export const getFileQuarantine = (id, subaccount) => {
    if (subaccount) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_FILE_QUARANTINE_REQUEST
            });
            API.hosting.subaccount.GET.fileQuarantine(id, subaccount)
                .then((response) => {
                    const hosting_file_quarantine_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_FILE_QUARANTINE_SUCCESS,
                        hosting_file_quarantine_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_FILE_QUARANTINE_FAIL,
                        hosting_file_quarantine_error: response
                    });
                });
        };
    }
    return (dispatch) => {
        dispatch({
            type: HOSTING_FILE_QUARANTINE_REQUEST
        });
        API.hosting.GET.fileQuarantine(id)
            .then((response) => {
                const hosting_file_quarantine_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_FILE_QUARANTINE_SUCCESS,
                    hosting_file_quarantine_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_FILE_QUARANTINE_FAIL,
                    hosting_file_quarantine_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   PERMISSIONS
 **********************************************************************************************************/

export const fixPermissions = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_PERMISSIONS_REQUEST
            });
            API.hosting.subaccount.POST.permissions(id, username)
                .then((response) => {
                    const hosting_permissions_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_PERMISSIONS_SUCCESS,
                        hosting_permissions_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_PERMISSIONS_FAIL,
                        hosting_permissions_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_PERMISSIONS_REQUEST
        });
        API.hosting.POST.permissions(id)
            .then((response) => {
                const hosting_permissions_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_PERMISSIONS_SUCCESS,
                    hosting_permissions_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_PERMISSIONS_FAIL,
                    hosting_permissions_error: response
                });
            });
    };
};
