import { useEditor } from '@tiptap/react';
import { RichTextEditorContext, newRichTextEditorTanstackStore } from 'components/Form/RichTextEditor/consts';
import { useExtensions } from 'components/Form/RichTextEditor/hooks/useExtensions';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import type { RichTextContent } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import React, { useEffect, useRef } from 'react';
import { NXDebug } from 'utilities/methods/debug';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RichTextEditorContextProviderProps = {
    children: React.ReactNode;
    value: RichTextContent;
    features: RichTextEditorNamespace.FeatureSet[];
    isDebugEnabled?: boolean;
};

export const RichTextEditorContextProvider: React.FC<RichTextEditorContextProviderProps> = ({ features, value, children, isDebugEnabled }) => {
    /***** HOOKS *****/
    const extensions = useExtensions(features);
    const editor = useEditor({
        extensions,
        content: value,
        parseOptions: {
            preserveWhitespace: true
        }
    });

    const richTextEditorContextTanstackStore = useRef(newRichTextEditorTanstackStore({ features, editor }));

    /***** HOOKS *****/
    useEffect(() => {
        if (isDebugEnabled) {
            NXDebug.log('features', features);
            NXDebug.log('extensions', extensions);
        }
    }, [isDebugEnabled]);

    /***** RENDER *****/
    return <RichTextEditorContext.Provider value={richTextEditorContextTanstackStore.current}>{children}</RichTextEditorContext.Provider>;
};
