import _ from 'lodash';
import { typedGet } from 'utilities/methods/objectMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/NXUtils';

// prettier-ignore
/**
 * Creates an optimistic method for modifying query cache data by setting the value of a specific path using a predicate.
 *
 * the query data at time of setting the new value can be restored using the returned restore method, useful if an error occurs or the adding of new data needs to be reverted.
 */
export function createOptimisticSetWithMethod<
	TParams extends any,
	TData extends NXQueryUtils.ApiData
>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>
) {
    function optimisticSet<
		TKey extends NXUtils.Path<TData>,
	>(
        params: TParams,
        setPath: TKey,
        predicate: (
			value: NXUtils.Choose<TData, TKey>,
		) => NXUtils.Choose<TData, TKey>
    ) {
		
        let previousData: TData | undefined;

        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            if (!_.has(oldData, setPath)) {
                return;
            }
			
            const clonedData = _.cloneDeep(oldData);

            const valueAtPath = typedGet(clonedData, setPath);
		
            const setWithResult =  predicate(valueAtPath);
			
            previousData = oldData;
			
            _.set(clonedData, setPath, setWithResult);
            return clonedData;
        });

        return {
            restore: () => {
                if (!previousData) {
                    return;
                }
                setQueryData(params, () => previousData);
            }
        };
    }
    return optimisticSet;
}
