import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useSingularNameCaveat = (property: KatanaNamespace.SectionDefinitions.PropertiesModified) => {
    return useGetCaveat(CaveatTypes.SINGULAR_NAME, property.caveats, property.name) as string;
};
