import { EditorContent } from '@tiptap/react';
import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';
import { useRichTextFeatures } from 'components/Form/RichTextEditor/hooks/useRichTextFeatures';
import { FloatingLinkEditorPlugin } from 'components/Form/RichTextEditor/plugins/FloatingLinkEditorPlugin';
import { JSONExtractorPlugin } from 'components/Form/RichTextEditor/plugins/JSONExtractor/JSONExtractorPlugin';
import { MarkdownExtractorPlugin } from 'components/Form/RichTextEditor/plugins/MarkdownExtractor/MarkdownExtractorPlugin';
import type { RichTextEditorPluginRendererNamespace } from 'components/Form/RichTextEditor/plugins/PluginRenderer/types';
import { ToolbarPlugin } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarPlugin';
import { ToolbarFocusManagerPlugin } from 'components/Form/RichTextEditor/plugins/ToolbarFocusManager/ToolbarFocusManagerPlugin';
import { useCollectionIncludes } from 'utilities/methods/collectionIncludes/useCollectionIncludes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Separate out rendering so that it has access to the RichTextEditorContext
 */
export const PluginRenderer: React.FC<RichTextEditorPluginRendererNamespace.Props> = ({ onChange, onBlur }) => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const [features] = useRichTextEditorContext('features');
    const isMarkdownEnabled = useCollectionIncludes(features, ['markdown']);
    const isJsonEnabled = useCollectionIncludes(features, ['tiptap']);
    const hasFloatingMenu = useRichTextFeatures('floating_toolbar');

    /***** RENDER *****/
    return (
        <>
            <ToolbarPlugin />
            <EditorContent editor={editor} className="RichTextEditor__editorContent" />
            <FloatingLinkEditorPlugin />
            {isJsonEnabled && <JSONExtractorPlugin onChange={onChange} onBlur={onBlur} />}
            {isMarkdownEnabled && <MarkdownExtractorPlugin onChange={onChange} />}
            {hasFloatingMenu && <ToolbarFocusManagerPlugin />}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
