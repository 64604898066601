import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { MINUTE } from 'utilities/consts';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/optimistic/createOptimisticMethods';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Artah.Katana.ID.Navigation.GET.All.Raw;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.navigation(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.navigation.GET(serviceID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);

function cancelQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.cancelQueries({
        queryKey: createQueryKey(serviceID)
    });
}

function resetQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID)
    });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    queryClient.prefetchQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the navigation  for the site
 */
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *  HOOK END
 * **********************************************************************************************************/

export const getNavigation = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    cancelQueries,
    prefetchQuery,
    resetQueries,
    optimistic: createOptimisticMethods(setQueryData),
    createQueryKey,
    createQueryOptions
});
