import { formatTipTapData } from 'components/Form/RichTextEditor/methods/formatTipTapData';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import type { RichTextContent } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import {
    isRichTextData,
    isTipTapDocumentJSON
} from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import { plaintTextToMarkdown } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/plainTextToMarkdown';
import _ from 'lodash';

export function formatRichTextData(value: RichTextContent, features: RichTextEditorNamespace.FeatureSet[]) {
    if (features.includes('tiptap') && !isRichTextData(value) && isTipTapDocumentJSON(value)) {
        return formatTipTapData(value);
    }

    if (features.includes('markdown') && _.isString(value)) {
        return {
            type: 'markdown',
            content: plaintTextToMarkdown(value)
        };
    }

    return value;
}
