/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RichTextEditor as RichTextEditor } from 'components/Form/RichTextEditor/_RichTextEditor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useStyle } from 'utilities/hooks/useStyle';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RichTextEditorTextArea.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RichTextEditorTextAreaNamespace } from 'components/Form/RichTextEditor/_RichTextEditorTextArea/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RichTextEditorTextArea: React.FC<RichTextEditorTextAreaNamespace.Props> = ({
    value,
    onChange,
    onBlur,
    features,
    rows = 5,
    initialValue
}) => {
    /***** HOOKS *****/
    const styles = useStyle({
        '--ContentEditableTextArea_rows': String(rows)
    });

    /***** RENDER *****/
    return (
        <RichTextEditor
            key={initialValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            style={styles}
            className={classNames('RichTextEditorTextArea', {
                'RichTextEditorTextArea--rows': rows
            })}
            features={features}
        />
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
