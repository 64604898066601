import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useRemoveEntryAndReassignFirstCaveat = (property: KatanaNamespace.SectionDefinitions.PropertiesModified) => {
    return useGetCaveat(CaveatTypes.REMOVE_ENTRY_AND_REASSIGN_FIRST, property.caveats) as string | null;
};
