import { setAxiosDefaults } from 'App/setAxiosDefaults';
import axios from 'axios';

/**
 * To be used for any tanstack query requests. This instance of axios uses the "transformResponse" default config, so that we can avoid storing the whole axios response in the query cache
 */
const nxAxios = axios.create();

// Set the same defaults as the global axios uses
setAxiosDefaults(nxAxios);

function NXAxiosInterceptor() {
    // Add an interceptor to remove all the axios config from the response
    nxAxios.interceptors.response.use(
        // Any status code that lie within the range of 2xx cause this function to trigger
        (response) => {
            if (typeof response === 'string') {
                return response;
            }
            return response.data;
        },
        // Any status codes that fall outside the range of 2xx cause this function to trigger
        (err) => {
            return Promise.reject(err?.response?.data || err);
        }
    );
}

export { nxAxios, NXAxiosInterceptor };
