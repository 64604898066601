import * as Sentry from '@sentry/react';
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _RichTextEditorInput as RichTextEditorInput } from 'components/Form/RichTextEditor/_RichTextEditorInput';
import type { RichTextEditorInputReduxFormNamespace } from 'components/Form/RichTextEditor/_RichTextEditorInput/wrappers/reduxForm/types';
import { formatRichTextData } from 'components/Form/RichTextEditor/methods/formatRichTextData';
import { isDefaultTipTapDocument } from 'components/Form/RichTextEditor/methods/isDefaultTipTapDocument';
import type { RichTextContent } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import { isRichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RichTextEditorInputReduxForm: React.FC<RichTextEditorInputReduxFormNamespace.Props> = (props) => {
    const { input, meta, label, features, initialValue, formItemInnerProps = {} } = props;
    const { name, value, onChange, onBlur } = input;

    /***** HOOKS *****/
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    const onChangeTransform = useCallback(
        (value: RichTextContent) => {
            if (!isDefaultTipTapDocument(value)) {
                dispatch(touch(meta.form, name));
            }

            const formattedValue = formatRichTextData(value, features);

            onChange(formattedValue);
        },
        [onChange]
    );

    function onBlurTransform(value: RichTextContent) {
        onBlur(formatRichTextData(value, features));
    }

    /***** RENDER HELPERS *****/
    const valueTransform = useMemo(() => {
        if (isRichTextData(value)) {
            return value.content ?? '';
        }
        if (_.isString(value)) {
            return value;
        }

        Sentry.captureException(new Error('Invalid value passed to `_RichTextEditorTextAreaReduxForm`'), { data: { props, input } });
        return '';
    }, [value]);
    return (
        <FormItem
            // Want to keep these here to help remind that these are normally required but are not implemented in _RichTextEditorTextAreaReduxForm
            // disabled={disabled}
            name={name}
        >
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} {...formItemInnerProps}>
                <RichTextEditorInput
                    // Want to keep these here to help remind that these are normally required but are not implemented in _RichTextEditorTextAreaReduxForm
                    // disabled={disabled}
                    initialValue={initialValue}
                    value={valueTransform}
                    onChange={onChangeTransform}
                    onBlur={onBlurTransform}
                    features={features}
                />
            </FormItemInner>
        </FormItem>
    );
};
