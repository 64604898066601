/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleColorThemeSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId, page } = useKatanaParams();
    const { submitSiteData } = useSiteDataPreviewValues();
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { mutateAsync: mutateUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(katanaServiceId, { onSuccess: _.noop });

    /***** HOOK RESULTS *****/
    return async function handleSubmit(values: Record<string, any>) {
        const clonedValues = _.cloneDeep(values);

        const updatePromise = mutateUpdateKatanaSiteInfo(clonedValues);

        submitSiteData([updatePromise]);

        const { restore } = katanaQuery.serviceID.service.optimistic.concat(katanaServiceId, 'data.attributes.launch_checklist', [
            'theme-and-color-completed'
        ]);

        try {
            await updatePromise;

            if (page !== 'setup') {
                pushNotification({ status: 200, message: 'Successfully updated site details.' });
            }

            performRouting();
        } catch (error) {
            console.error('Failed to update site details:', error);
            restore();
        }

        katanaQuery.serviceID.service.invalidateQueries(katanaServiceId);
    };
}
