import { z } from 'zod';

type TipTapDefaultDocument = z.infer<typeof tipTapDefaultDocumentSchema>;

const tipTapDefaultDocumentSchema = z.object({
    type: z.literal('doc'),
    content: z.array(
        z.object({
            type: z.literal('paragraph')
        })
    )
});

export function isDefaultTipTapDocument(value: unknown): value is TipTapDefaultDocument {
    return tipTapDefaultDocumentSchema.safeParse(value).success;
}
