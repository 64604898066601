import { useIsMutating, useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { newSectionOrganiserData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionOrganiserData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import type { PartialDeep } from 'type-fest';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaSiteSectionMutationParams = {
    serviceID: KatanaNamespace.ServiceID;
    katanaSectionID: KatanaNamespace.SectionDefinitionID;
};

type HandleMutationParams = {
    sectionDefinitionID: KatanaNamespace.SectionDefinitionID;
    attributes: PartialDeep<KatanaAPI.Katana.Site.ServiceID.Section.SectionID.GET.ModifiedSection_200['data']>;
};

function createMutationKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.sections.add(serviceID);
}

function _useIsMutating(serviceID: KatanaNamespace.ServiceID) {
    return useIsMutating({
        mutationKey: createMutationKey(serviceID)
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Katana add section
 */
function _useMutation({ serviceID, katanaSectionID }: KatanaSiteSectionMutationParams) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, katanaSectionID });

    async function handleMutation({ sectionDefinitionID, attributes }: HandleMutationParams) {
        const updatedAttributes = await handleModifyEntireAttributeObject(attributes);

        return KATANA_API.katana.site.service_id.sections.POST({ serviceID, sectionDefinitionID, attributes: updatedAttributes });
    }

    return useMutation({
        mutationFn: handleMutation,
        mutationKey: createMutationKey(serviceID),
        onError: (response) => {
            handleDefaultErrorNotification(response);
            service.invalidateQueries(serviceID);
        },
        onSuccess: (response) => {
            if (response.status !== 200) {
                return;
            }
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }
            service.invalidateQueries(serviceID);
            sections.invalidateQueries(serviceID);
            if (!_.has(response, 'data')) return;

            const correctResponse = section.createDataObject(response, {
                meta: {
                    media_attachments: []
                }
            });

            newSectionOrganiserData.addNewID(correctResponse.data.id);

            section.setQueryData({ serviceID, sectionID: correctResponse.data.id }, () => correctResponse);
            sections.optimistic.concat(serviceID, 'data', [{ ...correctResponse.data }]);
            service.optimistic.concat(serviceID, 'data.sections', [{ ...correctResponse.data }]);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const addSection = Object.freeze({
    useMutation: _useMutation,
    useIsMutating: _useIsMutating
});
