import { useRichTextFeatures } from 'components/Form/RichTextEditor/hooks/useRichTextFeatures';
import { textStylingDropdownClass } from 'components/Form/RichTextEditor/plugins/Toolbar/consts';
import type React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useElementSize } from 'utilities/hooks/useElementSize';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useCondensedBreakpoints = (toolBarRef: React.RefObject<HTMLDivElement>) => {
    /***** STATE *****/
    const [lastHeight, setLastHeight] = useState(0);
    const [isTextStylingCondensed, setIsTextStylingCondensed] = useState(false);
    const [isHeadingFormatCondensed, setIsHeadingFormatCondensed] = useState(false);

    /***** HOOKS *****/
    const toolBarBreakpoints = useRef<Partial<Record<'largestBreakPoint' | 'textStylingBreakpoint' | 'headingFormatBreakpoint', number>>>({});
    const activeBreakPoints = useRef<Partial<Record<'largestBreakPoint' | 'textStylingBreakpoint' | 'headingFormatBreakpoint', number>>>({});
    const hasBold = useRichTextFeatures('bold');
    const hasItalic = useRichTextFeatures('italic');
    const hasStrikethrough = useRichTextFeatures('strikethrough');
    const hasUnderline = useRichTextFeatures('underline');

    const { width = 0, height = 0 } = useElementSize({
        ref: toolBarRef,
        box: 'content-box'
    });

    /***** FUNCTIONS *****/
    function getToolBarItemsWidth() {
        const { current } = toolBarRef;
        if (!current) return 0;

        const additionalQuery = isTextStylingCondensed ? '' : `:not(.${textStylingDropdownClass}`;
        const allToolBarItems = current.querySelectorAll(`.ToolbarButton, .ToolbarDropdown${additionalQuery}`);

        return Array.from(allToolBarItems).reduce((acc, item) => {
            return acc + item.clientWidth;
        }, 0);
    }

    /***** EFFECTS *****/
    const hasMultipleTextStylings = [hasBold, hasItalic, hasStrikethrough, hasUnderline].filter(Boolean).length > 1;

    /**
     * This code is quite crude, if we require more breakpoints based of the size if children we can refactor this to be more dynamic
     */
    useLayoutEffect(() => {
        if (!width) return;

        // If the height changes, reset the breakpoints (this happens when the icons change size for example)
        if (lastHeight !== height) {
            toolBarBreakpoints.current = {};
        }
        setLastHeight(height);

        if (!height) return;
        const { current } = toolBarRef;
        if (!current) return;

        /**
         * We need to set the largest breakpoint to the value that has no condensed toolbar items so that we can calculate the condensed breakpoints
         */
        if (!isTextStylingCondensed && !isHeadingFormatCondensed) {
            toolBarBreakpoints.current.largestBreakPoint = getToolBarItemsWidth();
        }

        /**
         * if the largest breakpoint is not active and we have a largestBreakpoint available then we can set the textStylingBreakpoint so that it may be condensed
         */
        if (!activeBreakPoints.current.largestBreakPoint && toolBarBreakpoints.current.largestBreakPoint) {
            if (width <= toolBarBreakpoints.current.largestBreakPoint) {
                setIsTextStylingCondensed(true);
                activeBreakPoints.current.largestBreakPoint = toolBarBreakpoints.current.largestBreakPoint;
            }
        }

        /**
         * Revert text styling condense if the width is greater than breakpoint it got set at
         */
        if (activeBreakPoints.current.largestBreakPoint) {
            if (width > activeBreakPoints.current.largestBreakPoint) {
                setIsTextStylingCondensed(false);
                activeBreakPoints.current.largestBreakPoint = 0;
            }
        }

        /**
         * If the largest breakpoint is active and we have a largestBreakpoint available then we can set the textStylingBreakpoint so that it may be condensed
         */
        if (activeBreakPoints.current.largestBreakPoint && !isHeadingFormatCondensed) {
            const condensedTextStyleDropdown = current.querySelector(`.${textStylingDropdownClass}`);
            if (condensedTextStyleDropdown) {
                // condensedTextStyleDropdown.clientWidth;
                toolBarBreakpoints.current.headingFormatBreakpoint =
                    activeBreakPoints.current.largestBreakPoint - condensedTextStyleDropdown.clientWidth;
            }
        }

        if (activeBreakPoints.current.largestBreakPoint && hasMultipleTextStylings && isTextStylingCondensed && !isHeadingFormatCondensed) {
            const itemsWidth = getToolBarItemsWidth();
            toolBarBreakpoints.current.headingFormatBreakpoint = itemsWidth;
        }

        if (hasMultipleTextStylings) {
            if (toolBarBreakpoints.current.headingFormatBreakpoint && !isHeadingFormatCondensed) {
                if (width <= toolBarBreakpoints.current.headingFormatBreakpoint) {
                    setIsHeadingFormatCondensed(true);
                    activeBreakPoints.current.headingFormatBreakpoint = toolBarBreakpoints.current.headingFormatBreakpoint;
                }
            }
        } else {
            if (toolBarBreakpoints.current.largestBreakPoint && !isHeadingFormatCondensed) {
                if (width <= toolBarBreakpoints.current.largestBreakPoint) {
                    setIsHeadingFormatCondensed(true);
                    activeBreakPoints.current.headingFormatBreakpoint = toolBarBreakpoints.current.largestBreakPoint;
                }
            }
        }

        if (activeBreakPoints.current.headingFormatBreakpoint) {
            if (width > activeBreakPoints.current.headingFormatBreakpoint) {
                setIsHeadingFormatCondensed(false);
                activeBreakPoints.current.headingFormatBreakpoint = 0;
            }
        }
    }, [width, height]);

    return {
        isTextStylingCondensed: hasMultipleTextStylings && isTextStylingCondensed,
        isHeadingFormatCondensed
    };
};
