/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { validationMinimum } from 'utilities/methods/form/validations/validationMinimum';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function formFieldsMinRuleParser(validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) {
    return (value: any) => {
        return validationMinimum(value, validation?.value);
    };
}
