/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Revealer } from 'components/Revealer';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _SelectCard as SelectCard } from 'components/Form/CardSelectGrid/_SelectCard/_SelectCard';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_CardSelectGrid.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CardSelectGrid: React.FC<CardSelectGridNamespace.BaseProps> = ({
    selectionText = 'Choose this photo',
    options,
    itemsPerRow = 2,
    isHidingDisabled = false,
    alignItems,
    textAlign,
    cardContentGrow = true,
    value,
    onChange,
    onChangeTransform,
    valueTransform,
    disabled
}) => {
    const maxBaseRange = Math.min(itemsPerRow * 2, options.length);
    const baseRange: [number, number] = [0, isHidingDisabled ? options.length : maxBaseRange];
    /***** STATE *****/
    const transformedValue = valueTransform ? valueTransform(value) : value;

    // Only check this on mount as we don't want it opening and closing all the time
    const isValueInVisibleRange = useMemo(() => {
        if (!transformedValue) {
            return true;
        }
        const valueIndex = options.findIndex((option) =>
            [_.get(option, 'value') ?? '', _.get(option, 'fontID') ?? '', _.get(option, 'url') ?? ''].includes(transformedValue)
        );
        return valueIndex >= baseRange[0] && valueIndex < baseRange[1];
    }, [Boolean(transformedValue)]);

    /***** FUNCTIONS *****/
    function handleOnChange(_value: CardSelectGridNamespace.SelectCard.OnChangeValue) {
        onChange(onChangeTransform ? onChangeTransform(_value) : _value);
    }

    /***** RENDER *****/
    return (
        <Revealer.Wrappers.GridHide
            gridProps={{
                'columns': `repeat(${itemsPerRow}, 1fr)`,
                'alignItems--stretch': alignItems === 'stretch',
                'gap': 4
            }}
            itemsPerRow={itemsPerRow}
            data={options}
            isDefaultOpen={!isValueInVisibleRange}
            range={baseRange}
            className={classNames('CardSelectGrid', {
                'CardSelectGrid--cardContentGrow': cardContentGrow
            })}
        >
            {(option, i) => (
                <SelectCard
                    disabled={disabled}
                    key={i}
                    option={option}
                    selectionText={selectionText}
                    onChange={handleOnChange}
                    value={transformedValue}
                    textAlign={textAlign}
                />
            )}
        </Revealer.Wrappers.GridHide>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
