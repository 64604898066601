import NXBox from 'components/NXBox';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PropertyNXBoxDescription: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { caveats } = property;
    /***** HOOKS *****/
    const boxDescription = useGetCaveat(CaveatTypes.BOX_DESCRIPTION, caveats) as string | null | undefined;

    /***** RENDER HELPERS *****/
    if (!boxDescription) return null;
    return <NXBox.Description description={boxDescription} />;
};
