import { useRichTextEditor } from 'components/Form/RichTextEditor/hooks/useRichTextEditor';
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';
import { useCallback, useEffect, useMemo } from 'react';
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ToolbarFocusManagerPlugin = () => {
    /***** HOOKS *****/
    const editor = useRichTextEditor();
    const [, setCanShowFloatingToolbar] = useRichTextEditorContext('canShowFloatingToolbar');
    const [rootElement] = useRichTextEditorContext('rootElement');

    /***** FUNCTIONS *****/
    function enableShowingFloatingToolbar() {
        setCanShowFloatingToolbar(true);
    }

    const disableShowingFloatingToolbar = useCallback(() => {
        setCanShowFloatingToolbar(false);
    }, []);

    const clickAwayRefs = useMemo(() => [rootElement], [rootElement]);

    useClickAway(clickAwayRefs, disableShowingFloatingToolbar);

    useEffect(() => {});
    /***** EFFECTS *****/
    useEffect(() => {
        if (!editor) return;
        if (!rootElement) return;
        editor.on('focus', enableShowingFloatingToolbar);
        rootElement.addEventListener('focus', enableShowingFloatingToolbar);

        return () => {
            editor.off('focus', enableShowingFloatingToolbar);
            rootElement.removeEventListener('focus', enableShowingFloatingToolbar);
        };
    }, [editor, rootElement]);

    return null;
};
