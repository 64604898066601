import { RichTextEditorContent } from 'components/Form/RichTextEditor/_RichTextEditor/RichTextEditorContent';
import { useIsDebuggingRichText } from 'components/Form/RichTextEditor/hooks/useIsDebuggingRichText';
import { PluginRenderer } from 'components/Form/RichTextEditor/plugins/PluginRenderer/PluginRenderer';
import { RichTextEditorContextProvider } from 'components/Form/RichTextEditor/RichTextEditorContextProvider';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import { useComponentDebugTrackerCount } from 'components/StaffMenu/SuperUser/consts';
import type { RichTextContent } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import React, { useCallback } from 'react';
import { NXDebug } from 'utilities/methods/debug';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @prop {boolean} __debugEnabled - If true, logs the markdown string to the console on change
 */
export const _RichTextEditor: React.FC<RichTextEditorNamespace.Props> = ({
    value = '',
    onChange,
    onBlur,
    features = [],
    className,
    style,
    __debugEnabled = false
}) => {
    /***** HOOKS *****/
    const isDebugEnabled = useIsDebuggingRichText({ __debugEnabled });
    useComponentDebugTrackerCount('RichTextEditor');

    /***** FUNCTIONS *****/
    const handleOnChange = useCallback(
        (newValue: RichTextContent) => {
            if (isDebugEnabled) {
                NXDebug.log('newValue', newValue);
                NXDebug.log(JSON.stringify(newValue));
            }

            onChange?.(newValue);
        },
        [onChange, isDebugEnabled]
    );

    /***** RENDER *****/
    return (
        <RichTextEditorContextProvider features={features} value={value} isDebugEnabled={isDebugEnabled}>
            <RichTextEditorContent className={className} style={style}>
                <PluginRenderer onChange={handleOnChange} onBlur={onBlur} />
            </RichTextEditorContent>
        </RichTextEditorContextProvider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
